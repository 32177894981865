/* tslint:disable */
/* eslint-disable */
/**
 * Gradivo API
 * API endpoints for Gradivo.hr
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { CourseModule } from '../models';
// @ts-ignore
import type { DigitalTextbookSectionListingWithProblems } from '../models';
// @ts-ignore
import type { FrontofficePage } from '../models';
// @ts-ignore
import type { HighSchoolList } from '../models';
// @ts-ignore
import type { LastViewedCourseModule } from '../models';
// @ts-ignore
import type { LastViewedLessonRetrieve } from '../models';
// @ts-ignore
import type { LastViewedLessonRetrieveRequest } from '../models';
// @ts-ignore
import type { Lesson } from '../models';
// @ts-ignore
import type { PaginatedCourseModuleList } from '../models';
// @ts-ignore
import type { PaginatedFrontofficePageList } from '../models';
// @ts-ignore
import type { PaginatedLessonListList } from '../models';
// @ts-ignore
import type { PaginatedProblemFrontofficeListList } from '../models';
// @ts-ignore
import type { PaginatedSelfRetrieveUserList } from '../models';
// @ts-ignore
import type { PaginatedViewedLessonListList } from '../models';
// @ts-ignore
import type { PaginatedViewedProblemListList } from '../models';
// @ts-ignore
import type { PatchedLastViewedLessonRetrieveRequest } from '../models';
// @ts-ignore
import type { PatchedSelfUpdateUserRequest } from '../models';
// @ts-ignore
import type { PatchedViewedProblemListRequest } from '../models';
// @ts-ignore
import type { Problem } from '../models';
// @ts-ignore
import type { ProblemSourceDashboard } from '../models';
// @ts-ignore
import type { ProblemSourceExam } from '../models';
// @ts-ignore
import type { ProblemSourcePractice } from '../models';
// @ts-ignore
import type { SelfRetrieveUser } from '../models';
// @ts-ignore
import type { SelfRetrieveUserRequest } from '../models';
// @ts-ignore
import type { SelfUpdateUser } from '../models';
// @ts-ignore
import type { UniversityList } from '../models';
// @ts-ignore
import type { UniversityProgramsList } from '../models';
// @ts-ignore
import type { ViewedLessonCreate } from '../models';
// @ts-ignore
import type { ViewedLessonCreateRequest } from '../models';
// @ts-ignore
import type { ViewedProblemCreate } from '../models';
// @ts-ignore
import type { ViewedProblemCreateRequest } from '../models';
// @ts-ignore
import type { ViewedProblemList } from '../models';
// @ts-ignore
import type { ViewedProblemListRequest } from '../models';
/**
 * FrontofficeApi - axios parameter creator
 * @export
 */
export const FrontofficeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ViewedLessonCreateRequest} viewedLessonCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createViewedLesson: async (viewedLessonCreateRequest: ViewedLessonCreateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'viewedLessonCreateRequest' is not null or undefined
            assertParamExists('createViewedLesson', 'viewedLessonCreateRequest', viewedLessonCreateRequest)
            const localVarPath = `/wt/frontoffice/api/viewed-lesson/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(viewedLessonCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ViewedProblemCreateRequest} viewedProblemCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createViewedProblem: async (viewedProblemCreateRequest: ViewedProblemCreateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'viewedProblemCreateRequest' is not null or undefined
            assertParamExists('createViewedProblem', 'viewedProblemCreateRequest', viewedProblemCreateRequest)
            const localVarPath = `/wt/frontoffice/api/viewed-problem/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(viewedProblemCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frontofficeApiCourseModuleList: async (limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/frontoffice/api/course-module/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frontofficeApiCourseModulePreviewRetrieve: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/frontoffice/api/course-module/preview/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SelfRetrieveUserRequest} selfRetrieveUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frontofficeApiCurrentUserCreate: async (selfRetrieveUserRequest: SelfRetrieveUserRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'selfRetrieveUserRequest' is not null or undefined
            assertParamExists('frontofficeApiCurrentUserCreate', 'selfRetrieveUserRequest', selfRetrieveUserRequest)
            const localVarPath = `/wt/frontoffice/api/current-user/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(selfRetrieveUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frontofficeApiCurrentUserDestroy: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('frontofficeApiCurrentUserDestroy', 'id', id)
            const localVarPath = `/wt/frontoffice/api/current-user/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frontofficeApiCurrentUserList: async (limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/frontoffice/api/current-user/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this user.
         * @param {SelfRetrieveUserRequest} selfRetrieveUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frontofficeApiCurrentUserUpdate: async (id: number, selfRetrieveUserRequest: SelfRetrieveUserRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('frontofficeApiCurrentUserUpdate', 'id', id)
            // verify required parameter 'selfRetrieveUserRequest' is not null or undefined
            assertParamExists('frontofficeApiCurrentUserUpdate', 'selfRetrieveUserRequest', selfRetrieveUserRequest)
            const localVarPath = `/wt/frontoffice/api/current-user/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(selfRetrieveUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frontofficeApiPageList: async (limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/frontoffice/api/page/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frontofficeApiProblemSourceDashboardPreviewRetrieve: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/frontoffice/api/problem-source-dashboard/preview/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this problem source.
         * @param {string} [contentType] 
         * @param {boolean} [inPreviewPanel] 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frontofficeApiProblemSourceDashboardRetrieve: async (id: number, contentType?: string, inPreviewPanel?: boolean, token?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('frontofficeApiProblemSourceDashboardRetrieve', 'id', id)
            const localVarPath = `/wt/frontoffice/api/problem-source-dashboard/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (contentType !== undefined) {
                localVarQueryParameter['content_type'] = contentType;
            }

            if (inPreviewPanel !== undefined) {
                localVarQueryParameter['in_preview_panel'] = inPreviewPanel;
            }

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frontofficeApiProblemSourceExamPreviewRetrieve: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/frontoffice/api/problem-source-exam/preview/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this problem source.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frontofficeApiProblemSourceExamRetrieve: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('frontofficeApiProblemSourceExamRetrieve', 'id', id)
            const localVarPath = `/wt/frontoffice/api/problem-source-exam/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frontofficeApiProblemSourcePracticePreviewRetrieve: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/frontoffice/api/problem-source-practice/preview/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this problem source.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frontofficeApiProblemSourcePracticeRetrieve: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('frontofficeApiProblemSourcePracticeRetrieve', 'id', id)
            const localVarPath = `/wt/frontoffice/api/problem-source-practice/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Viewed Lesson.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frontofficeApiViewedLessonDestroy: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('frontofficeApiViewedLessonDestroy', 'id', id)
            const localVarPath = `/wt/frontoffice/api/viewed-lesson/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Viewed Lesson.
         * @param {PatchedLastViewedLessonRetrieveRequest} [patchedLastViewedLessonRetrieveRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frontofficeApiViewedLessonPartialUpdate: async (id: number, patchedLastViewedLessonRetrieveRequest?: PatchedLastViewedLessonRetrieveRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('frontofficeApiViewedLessonPartialUpdate', 'id', id)
            const localVarPath = `/wt/frontoffice/api/viewed-lesson/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedLastViewedLessonRetrieveRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Viewed Lesson.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frontofficeApiViewedLessonRetrieve: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('frontofficeApiViewedLessonRetrieve', 'id', id)
            const localVarPath = `/wt/frontoffice/api/viewed-lesson/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Viewed Lesson.
         * @param {LastViewedLessonRetrieveRequest} lastViewedLessonRetrieveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frontofficeApiViewedLessonUpdate: async (id: number, lastViewedLessonRetrieveRequest: LastViewedLessonRetrieveRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('frontofficeApiViewedLessonUpdate', 'id', id)
            // verify required parameter 'lastViewedLessonRetrieveRequest' is not null or undefined
            assertParamExists('frontofficeApiViewedLessonUpdate', 'lastViewedLessonRetrieveRequest', lastViewedLessonRetrieveRequest)
            const localVarPath = `/wt/frontoffice/api/viewed-lesson/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(lastViewedLessonRetrieveRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Viewed Problem.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frontofficeApiViewedProblemDestroy: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('frontofficeApiViewedProblemDestroy', 'id', id)
            const localVarPath = `/wt/frontoffice/api/viewed-problem/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Viewed Problem.
         * @param {PatchedViewedProblemListRequest} [patchedViewedProblemListRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frontofficeApiViewedProblemPartialUpdate: async (id: number, patchedViewedProblemListRequest?: PatchedViewedProblemListRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('frontofficeApiViewedProblemPartialUpdate', 'id', id)
            const localVarPath = `/wt/frontoffice/api/viewed-problem/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedViewedProblemListRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Viewed Problem.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frontofficeApiViewedProblemRetrieve: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('frontofficeApiViewedProblemRetrieve', 'id', id)
            const localVarPath = `/wt/frontoffice/api/viewed-problem/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Viewed Problem.
         * @param {ViewedProblemListRequest} viewedProblemListRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frontofficeApiViewedProblemUpdate: async (id: number, viewedProblemListRequest: ViewedProblemListRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('frontofficeApiViewedProblemUpdate', 'id', id)
            // verify required parameter 'viewedProblemListRequest' is not null or undefined
            assertParamExists('frontofficeApiViewedProblemUpdate', 'viewedProblemListRequest', viewedProblemListRequest)
            const localVarPath = `/wt/frontoffice/api/viewed-problem/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(viewedProblemListRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this course module.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseModule: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCourseModule', 'id', id)
            const localVarPath = `/wt/frontoffice/api/course-module/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUser: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCurrentUser', 'id', id)
            const localVarPath = `/wt/frontoffice/api/current-user/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} courseId Course id
         * @param {number} id A unique integer value identifying this digital textbook section listing.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDigitalTextbookSectionListing: async (courseId: number, id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('getDigitalTextbookSectionListing', 'courseId', courseId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDigitalTextbookSectionListing', 'id', id)
            const localVarPath = `/wt/frontoffice/api/digital-textbook-section-listing/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (courseId !== undefined) {
                localVarQueryParameter['course_id'] = courseId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this frontoffice page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFrontofficePage: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFrontofficePage', 'id', id)
            const localVarPath = `/wt/frontoffice/api/page/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHighSchoolList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/frontoffice/api/high-school-list/list/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastViewedCourseModule: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/frontoffice/api/viewed-lesson/last-viewed-course-module/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} course 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastViewedLessonInCourse: async (course: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'course' is not null or undefined
            assertParamExists('getLastViewedLessonInCourse', 'course', course)
            const localVarPath = `/wt/frontoffice/api/viewed-lesson/last-for-course/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (course !== undefined) {
                localVarQueryParameter['course'] = course;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} courseModule 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastViewedLessonInCourseModule: async (courseModule: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseModule' is not null or undefined
            assertParamExists('getLastViewedLessonInCourseModule', 'courseModule', courseModule)
            const localVarPath = `/wt/frontoffice/api/viewed-lesson/last-for-course-module/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (courseModule !== undefined) {
                localVarQueryParameter['course_module'] = courseModule;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Lesson.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLesson: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getLesson', 'id', id)
            const localVarPath = `/wt/frontoffice/api/lesson/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLessonList: async (limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/frontoffice/api/lesson/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this problem.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProblem2: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProblem2', 'id', id)
            const localVarPath = `/wt/frontoffice/api/problem/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {GetProblemListVisibilityStatusEnum} [visibilityStatus] * &#x60;free_for_registered&#x60; - Free for registered user * &#x60;free_preview&#x60; - Free preview * &#x60;available&#x60; - Available * &#x60;disabled&#x60; - Disabled * &#x60;hidden&#x60; - Hidden
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProblemList: async (limit?: number, offset?: number, visibilityStatus?: GetProblemListVisibilityStatusEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/frontoffice/api/problem/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (visibilityStatus !== undefined) {
                localVarQueryParameter['visibility_status'] = visibilityStatus;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUniversityList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/frontoffice/api/university/list/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUniversityProgramsList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/frontoffice/api/university/programs-list/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} courseModule 
         * @param {number} [course] 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {number} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getViewedLessonList: async (courseModule: number, course?: number, limit?: number, offset?: number, user?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseModule' is not null or undefined
            assertParamExists('getViewedLessonList', 'courseModule', courseModule)
            const localVarPath = `/wt/frontoffice/api/viewed-lesson/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (course !== undefined) {
                localVarQueryParameter['course'] = course;
            }

            if (courseModule !== undefined) {
                localVarQueryParameter['course_module'] = courseModule;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (user !== undefined) {
                localVarQueryParameter['user'] = user;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [digitalTextbookSectionListing] 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {number} [problemSource] 
         * @param {number} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getViewedProblemList: async (digitalTextbookSectionListing?: number, limit?: number, offset?: number, problemSource?: number, user?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/frontoffice/api/viewed-problem/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (digitalTextbookSectionListing !== undefined) {
                localVarQueryParameter['digital_textbook_section_listing'] = digitalTextbookSectionListing;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (problemSource !== undefined) {
                localVarQueryParameter['problem_source'] = problemSource;
            }

            if (user !== undefined) {
                localVarQueryParameter['user'] = user;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this user.
         * @param {PatchedSelfUpdateUserRequest} [patchedSelfUpdateUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCurrentUser: async (id: number, patchedSelfUpdateUserRequest?: PatchedSelfUpdateUserRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateCurrentUser', 'id', id)
            const localVarPath = `/wt/frontoffice/api/current-user/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedSelfUpdateUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FrontofficeApi - functional programming interface
 * @export
 */
export const FrontofficeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FrontofficeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ViewedLessonCreateRequest} viewedLessonCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createViewedLesson(viewedLessonCreateRequest: ViewedLessonCreateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ViewedLessonCreate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createViewedLesson(viewedLessonCreateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrontofficeApi.createViewedLesson']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ViewedProblemCreateRequest} viewedProblemCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createViewedProblem(viewedProblemCreateRequest: ViewedProblemCreateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ViewedProblemCreate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createViewedProblem(viewedProblemCreateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrontofficeApi.createViewedProblem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async frontofficeApiCourseModuleList(limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedCourseModuleList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.frontofficeApiCourseModuleList(limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrontofficeApi.frontofficeApiCourseModuleList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async frontofficeApiCourseModulePreviewRetrieve(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourseModule>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.frontofficeApiCourseModulePreviewRetrieve(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrontofficeApi.frontofficeApiCourseModulePreviewRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {SelfRetrieveUserRequest} selfRetrieveUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async frontofficeApiCurrentUserCreate(selfRetrieveUserRequest: SelfRetrieveUserRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SelfRetrieveUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.frontofficeApiCurrentUserCreate(selfRetrieveUserRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrontofficeApi.frontofficeApiCurrentUserCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async frontofficeApiCurrentUserDestroy(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.frontofficeApiCurrentUserDestroy(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrontofficeApi.frontofficeApiCurrentUserDestroy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async frontofficeApiCurrentUserList(limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedSelfRetrieveUserList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.frontofficeApiCurrentUserList(limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrontofficeApi.frontofficeApiCurrentUserList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this user.
         * @param {SelfRetrieveUserRequest} selfRetrieveUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async frontofficeApiCurrentUserUpdate(id: number, selfRetrieveUserRequest: SelfRetrieveUserRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SelfRetrieveUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.frontofficeApiCurrentUserUpdate(id, selfRetrieveUserRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrontofficeApi.frontofficeApiCurrentUserUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async frontofficeApiPageList(limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedFrontofficePageList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.frontofficeApiPageList(limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrontofficeApi.frontofficeApiPageList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async frontofficeApiProblemSourceDashboardPreviewRetrieve(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProblemSourceDashboard>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.frontofficeApiProblemSourceDashboardPreviewRetrieve(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrontofficeApi.frontofficeApiProblemSourceDashboardPreviewRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this problem source.
         * @param {string} [contentType] 
         * @param {boolean} [inPreviewPanel] 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async frontofficeApiProblemSourceDashboardRetrieve(id: number, contentType?: string, inPreviewPanel?: boolean, token?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProblemSourceDashboard>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.frontofficeApiProblemSourceDashboardRetrieve(id, contentType, inPreviewPanel, token, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrontofficeApi.frontofficeApiProblemSourceDashboardRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async frontofficeApiProblemSourceExamPreviewRetrieve(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProblemSourceExam>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.frontofficeApiProblemSourceExamPreviewRetrieve(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrontofficeApi.frontofficeApiProblemSourceExamPreviewRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this problem source.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async frontofficeApiProblemSourceExamRetrieve(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProblemSourceExam>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.frontofficeApiProblemSourceExamRetrieve(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrontofficeApi.frontofficeApiProblemSourceExamRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async frontofficeApiProblemSourcePracticePreviewRetrieve(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProblemSourcePractice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.frontofficeApiProblemSourcePracticePreviewRetrieve(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrontofficeApi.frontofficeApiProblemSourcePracticePreviewRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this problem source.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async frontofficeApiProblemSourcePracticeRetrieve(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProblemSourcePractice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.frontofficeApiProblemSourcePracticeRetrieve(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrontofficeApi.frontofficeApiProblemSourcePracticeRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Viewed Lesson.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async frontofficeApiViewedLessonDestroy(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.frontofficeApiViewedLessonDestroy(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrontofficeApi.frontofficeApiViewedLessonDestroy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Viewed Lesson.
         * @param {PatchedLastViewedLessonRetrieveRequest} [patchedLastViewedLessonRetrieveRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async frontofficeApiViewedLessonPartialUpdate(id: number, patchedLastViewedLessonRetrieveRequest?: PatchedLastViewedLessonRetrieveRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LastViewedLessonRetrieve>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.frontofficeApiViewedLessonPartialUpdate(id, patchedLastViewedLessonRetrieveRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrontofficeApi.frontofficeApiViewedLessonPartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Viewed Lesson.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async frontofficeApiViewedLessonRetrieve(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LastViewedLessonRetrieve>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.frontofficeApiViewedLessonRetrieve(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrontofficeApi.frontofficeApiViewedLessonRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Viewed Lesson.
         * @param {LastViewedLessonRetrieveRequest} lastViewedLessonRetrieveRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async frontofficeApiViewedLessonUpdate(id: number, lastViewedLessonRetrieveRequest: LastViewedLessonRetrieveRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LastViewedLessonRetrieve>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.frontofficeApiViewedLessonUpdate(id, lastViewedLessonRetrieveRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrontofficeApi.frontofficeApiViewedLessonUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Viewed Problem.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async frontofficeApiViewedProblemDestroy(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.frontofficeApiViewedProblemDestroy(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrontofficeApi.frontofficeApiViewedProblemDestroy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Viewed Problem.
         * @param {PatchedViewedProblemListRequest} [patchedViewedProblemListRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async frontofficeApiViewedProblemPartialUpdate(id: number, patchedViewedProblemListRequest?: PatchedViewedProblemListRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ViewedProblemList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.frontofficeApiViewedProblemPartialUpdate(id, patchedViewedProblemListRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrontofficeApi.frontofficeApiViewedProblemPartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Viewed Problem.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async frontofficeApiViewedProblemRetrieve(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ViewedProblemList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.frontofficeApiViewedProblemRetrieve(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrontofficeApi.frontofficeApiViewedProblemRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Viewed Problem.
         * @param {ViewedProblemListRequest} viewedProblemListRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async frontofficeApiViewedProblemUpdate(id: number, viewedProblemListRequest: ViewedProblemListRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ViewedProblemList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.frontofficeApiViewedProblemUpdate(id, viewedProblemListRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrontofficeApi.frontofficeApiViewedProblemUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this course module.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCourseModule(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourseModule>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCourseModule(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrontofficeApi.getCourseModule']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentUser(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SelfRetrieveUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentUser(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrontofficeApi.getCurrentUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} courseId Course id
         * @param {number} id A unique integer value identifying this digital textbook section listing.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDigitalTextbookSectionListing(courseId: number, id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DigitalTextbookSectionListingWithProblems>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDigitalTextbookSectionListing(courseId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrontofficeApi.getDigitalTextbookSectionListing']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this frontoffice page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFrontofficePage(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FrontofficePage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFrontofficePage(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrontofficeApi.getFrontofficePage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHighSchoolList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HighSchoolList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHighSchoolList(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrontofficeApi.getHighSchoolList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLastViewedCourseModule(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LastViewedCourseModule>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLastViewedCourseModule(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrontofficeApi.getLastViewedCourseModule']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} course 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLastViewedLessonInCourse(course: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LastViewedLessonRetrieve>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLastViewedLessonInCourse(course, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrontofficeApi.getLastViewedLessonInCourse']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} courseModule 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLastViewedLessonInCourseModule(courseModule: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LastViewedLessonRetrieve>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLastViewedLessonInCourseModule(courseModule, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrontofficeApi.getLastViewedLessonInCourseModule']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Lesson.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLesson(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Lesson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLesson(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrontofficeApi.getLesson']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLessonList(limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedLessonListList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLessonList(limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrontofficeApi.getLessonList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this problem.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProblem2(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Problem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProblem2(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrontofficeApi.getProblem2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {GetProblemListVisibilityStatusEnum} [visibilityStatus] * &#x60;free_for_registered&#x60; - Free for registered user * &#x60;free_preview&#x60; - Free preview * &#x60;available&#x60; - Available * &#x60;disabled&#x60; - Disabled * &#x60;hidden&#x60; - Hidden
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProblemList(limit?: number, offset?: number, visibilityStatus?: GetProblemListVisibilityStatusEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedProblemFrontofficeListList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProblemList(limit, offset, visibilityStatus, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrontofficeApi.getProblemList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUniversityList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UniversityList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUniversityList(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrontofficeApi.getUniversityList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUniversityProgramsList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UniversityProgramsList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUniversityProgramsList(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrontofficeApi.getUniversityProgramsList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} courseModule 
         * @param {number} [course] 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {number} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getViewedLessonList(courseModule: number, course?: number, limit?: number, offset?: number, user?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedViewedLessonListList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getViewedLessonList(courseModule, course, limit, offset, user, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrontofficeApi.getViewedLessonList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [digitalTextbookSectionListing] 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {number} [problemSource] 
         * @param {number} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getViewedProblemList(digitalTextbookSectionListing?: number, limit?: number, offset?: number, problemSource?: number, user?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedViewedProblemListList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getViewedProblemList(digitalTextbookSectionListing, limit, offset, problemSource, user, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrontofficeApi.getViewedProblemList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this user.
         * @param {PatchedSelfUpdateUserRequest} [patchedSelfUpdateUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCurrentUser(id: number, patchedSelfUpdateUserRequest?: PatchedSelfUpdateUserRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SelfUpdateUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCurrentUser(id, patchedSelfUpdateUserRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrontofficeApi.updateCurrentUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * FrontofficeApi - factory interface
 * @export
 */
export const FrontofficeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FrontofficeApiFp(configuration)
    return {
        /**
         * 
         * @param {FrontofficeApiCreateViewedLessonRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createViewedLesson(requestParameters: FrontofficeApiCreateViewedLessonRequest, options?: RawAxiosRequestConfig): AxiosPromise<ViewedLessonCreate> {
            return localVarFp.createViewedLesson(requestParameters.viewedLessonCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FrontofficeApiCreateViewedProblemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createViewedProblem(requestParameters: FrontofficeApiCreateViewedProblemRequest, options?: RawAxiosRequestConfig): AxiosPromise<ViewedProblemCreate> {
            return localVarFp.createViewedProblem(requestParameters.viewedProblemCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FrontofficeApiFrontofficeApiCourseModuleListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frontofficeApiCourseModuleList(requestParameters: FrontofficeApiFrontofficeApiCourseModuleListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedCourseModuleList> {
            return localVarFp.frontofficeApiCourseModuleList(requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frontofficeApiCourseModulePreviewRetrieve(options?: RawAxiosRequestConfig): AxiosPromise<CourseModule> {
            return localVarFp.frontofficeApiCourseModulePreviewRetrieve(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FrontofficeApiFrontofficeApiCurrentUserCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frontofficeApiCurrentUserCreate(requestParameters: FrontofficeApiFrontofficeApiCurrentUserCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<SelfRetrieveUser> {
            return localVarFp.frontofficeApiCurrentUserCreate(requestParameters.selfRetrieveUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FrontofficeApiFrontofficeApiCurrentUserDestroyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frontofficeApiCurrentUserDestroy(requestParameters: FrontofficeApiFrontofficeApiCurrentUserDestroyRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.frontofficeApiCurrentUserDestroy(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FrontofficeApiFrontofficeApiCurrentUserListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frontofficeApiCurrentUserList(requestParameters: FrontofficeApiFrontofficeApiCurrentUserListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedSelfRetrieveUserList> {
            return localVarFp.frontofficeApiCurrentUserList(requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FrontofficeApiFrontofficeApiCurrentUserUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frontofficeApiCurrentUserUpdate(requestParameters: FrontofficeApiFrontofficeApiCurrentUserUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<SelfRetrieveUser> {
            return localVarFp.frontofficeApiCurrentUserUpdate(requestParameters.id, requestParameters.selfRetrieveUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FrontofficeApiFrontofficeApiPageListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frontofficeApiPageList(requestParameters: FrontofficeApiFrontofficeApiPageListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedFrontofficePageList> {
            return localVarFp.frontofficeApiPageList(requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frontofficeApiProblemSourceDashboardPreviewRetrieve(options?: RawAxiosRequestConfig): AxiosPromise<ProblemSourceDashboard> {
            return localVarFp.frontofficeApiProblemSourceDashboardPreviewRetrieve(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FrontofficeApiFrontofficeApiProblemSourceDashboardRetrieveRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frontofficeApiProblemSourceDashboardRetrieve(requestParameters: FrontofficeApiFrontofficeApiProblemSourceDashboardRetrieveRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProblemSourceDashboard> {
            return localVarFp.frontofficeApiProblemSourceDashboardRetrieve(requestParameters.id, requestParameters.contentType, requestParameters.inPreviewPanel, requestParameters.token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frontofficeApiProblemSourceExamPreviewRetrieve(options?: RawAxiosRequestConfig): AxiosPromise<ProblemSourceExam> {
            return localVarFp.frontofficeApiProblemSourceExamPreviewRetrieve(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FrontofficeApiFrontofficeApiProblemSourceExamRetrieveRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frontofficeApiProblemSourceExamRetrieve(requestParameters: FrontofficeApiFrontofficeApiProblemSourceExamRetrieveRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProblemSourceExam> {
            return localVarFp.frontofficeApiProblemSourceExamRetrieve(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frontofficeApiProblemSourcePracticePreviewRetrieve(options?: RawAxiosRequestConfig): AxiosPromise<ProblemSourcePractice> {
            return localVarFp.frontofficeApiProblemSourcePracticePreviewRetrieve(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FrontofficeApiFrontofficeApiProblemSourcePracticeRetrieveRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frontofficeApiProblemSourcePracticeRetrieve(requestParameters: FrontofficeApiFrontofficeApiProblemSourcePracticeRetrieveRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProblemSourcePractice> {
            return localVarFp.frontofficeApiProblemSourcePracticeRetrieve(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FrontofficeApiFrontofficeApiViewedLessonDestroyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frontofficeApiViewedLessonDestroy(requestParameters: FrontofficeApiFrontofficeApiViewedLessonDestroyRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.frontofficeApiViewedLessonDestroy(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FrontofficeApiFrontofficeApiViewedLessonPartialUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frontofficeApiViewedLessonPartialUpdate(requestParameters: FrontofficeApiFrontofficeApiViewedLessonPartialUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<LastViewedLessonRetrieve> {
            return localVarFp.frontofficeApiViewedLessonPartialUpdate(requestParameters.id, requestParameters.patchedLastViewedLessonRetrieveRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FrontofficeApiFrontofficeApiViewedLessonRetrieveRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frontofficeApiViewedLessonRetrieve(requestParameters: FrontofficeApiFrontofficeApiViewedLessonRetrieveRequest, options?: RawAxiosRequestConfig): AxiosPromise<LastViewedLessonRetrieve> {
            return localVarFp.frontofficeApiViewedLessonRetrieve(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FrontofficeApiFrontofficeApiViewedLessonUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frontofficeApiViewedLessonUpdate(requestParameters: FrontofficeApiFrontofficeApiViewedLessonUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<LastViewedLessonRetrieve> {
            return localVarFp.frontofficeApiViewedLessonUpdate(requestParameters.id, requestParameters.lastViewedLessonRetrieveRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FrontofficeApiFrontofficeApiViewedProblemDestroyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frontofficeApiViewedProblemDestroy(requestParameters: FrontofficeApiFrontofficeApiViewedProblemDestroyRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.frontofficeApiViewedProblemDestroy(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FrontofficeApiFrontofficeApiViewedProblemPartialUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frontofficeApiViewedProblemPartialUpdate(requestParameters: FrontofficeApiFrontofficeApiViewedProblemPartialUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<ViewedProblemList> {
            return localVarFp.frontofficeApiViewedProblemPartialUpdate(requestParameters.id, requestParameters.patchedViewedProblemListRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FrontofficeApiFrontofficeApiViewedProblemRetrieveRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frontofficeApiViewedProblemRetrieve(requestParameters: FrontofficeApiFrontofficeApiViewedProblemRetrieveRequest, options?: RawAxiosRequestConfig): AxiosPromise<ViewedProblemList> {
            return localVarFp.frontofficeApiViewedProblemRetrieve(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FrontofficeApiFrontofficeApiViewedProblemUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        frontofficeApiViewedProblemUpdate(requestParameters: FrontofficeApiFrontofficeApiViewedProblemUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<ViewedProblemList> {
            return localVarFp.frontofficeApiViewedProblemUpdate(requestParameters.id, requestParameters.viewedProblemListRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FrontofficeApiGetCourseModuleRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseModule(requestParameters: FrontofficeApiGetCourseModuleRequest, options?: RawAxiosRequestConfig): AxiosPromise<CourseModule> {
            return localVarFp.getCourseModule(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FrontofficeApiGetCurrentUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUser(requestParameters: FrontofficeApiGetCurrentUserRequest, options?: RawAxiosRequestConfig): AxiosPromise<SelfRetrieveUser> {
            return localVarFp.getCurrentUser(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FrontofficeApiGetDigitalTextbookSectionListingRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDigitalTextbookSectionListing(requestParameters: FrontofficeApiGetDigitalTextbookSectionListingRequest, options?: RawAxiosRequestConfig): AxiosPromise<DigitalTextbookSectionListingWithProblems> {
            return localVarFp.getDigitalTextbookSectionListing(requestParameters.courseId, requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FrontofficeApiGetFrontofficePageRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFrontofficePage(requestParameters: FrontofficeApiGetFrontofficePageRequest, options?: RawAxiosRequestConfig): AxiosPromise<FrontofficePage> {
            return localVarFp.getFrontofficePage(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHighSchoolList(options?: RawAxiosRequestConfig): AxiosPromise<Array<HighSchoolList>> {
            return localVarFp.getHighSchoolList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastViewedCourseModule(options?: RawAxiosRequestConfig): AxiosPromise<LastViewedCourseModule> {
            return localVarFp.getLastViewedCourseModule(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FrontofficeApiGetLastViewedLessonInCourseRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastViewedLessonInCourse(requestParameters: FrontofficeApiGetLastViewedLessonInCourseRequest, options?: RawAxiosRequestConfig): AxiosPromise<LastViewedLessonRetrieve> {
            return localVarFp.getLastViewedLessonInCourse(requestParameters.course, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FrontofficeApiGetLastViewedLessonInCourseModuleRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastViewedLessonInCourseModule(requestParameters: FrontofficeApiGetLastViewedLessonInCourseModuleRequest, options?: RawAxiosRequestConfig): AxiosPromise<LastViewedLessonRetrieve> {
            return localVarFp.getLastViewedLessonInCourseModule(requestParameters.courseModule, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FrontofficeApiGetLessonRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLesson(requestParameters: FrontofficeApiGetLessonRequest, options?: RawAxiosRequestConfig): AxiosPromise<Lesson> {
            return localVarFp.getLesson(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FrontofficeApiGetLessonListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLessonList(requestParameters: FrontofficeApiGetLessonListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedLessonListList> {
            return localVarFp.getLessonList(requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FrontofficeApiGetProblem2Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProblem2(requestParameters: FrontofficeApiGetProblem2Request, options?: RawAxiosRequestConfig): AxiosPromise<Problem> {
            return localVarFp.getProblem2(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FrontofficeApiGetProblemListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProblemList(requestParameters: FrontofficeApiGetProblemListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedProblemFrontofficeListList> {
            return localVarFp.getProblemList(requestParameters.limit, requestParameters.offset, requestParameters.visibilityStatus, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUniversityList(options?: RawAxiosRequestConfig): AxiosPromise<Array<UniversityList>> {
            return localVarFp.getUniversityList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUniversityProgramsList(options?: RawAxiosRequestConfig): AxiosPromise<Array<UniversityProgramsList>> {
            return localVarFp.getUniversityProgramsList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FrontofficeApiGetViewedLessonListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getViewedLessonList(requestParameters: FrontofficeApiGetViewedLessonListRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedViewedLessonListList> {
            return localVarFp.getViewedLessonList(requestParameters.courseModule, requestParameters.course, requestParameters.limit, requestParameters.offset, requestParameters.user, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FrontofficeApiGetViewedProblemListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getViewedProblemList(requestParameters: FrontofficeApiGetViewedProblemListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedViewedProblemListList> {
            return localVarFp.getViewedProblemList(requestParameters.digitalTextbookSectionListing, requestParameters.limit, requestParameters.offset, requestParameters.problemSource, requestParameters.user, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FrontofficeApiUpdateCurrentUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCurrentUser(requestParameters: FrontofficeApiUpdateCurrentUserRequest, options?: RawAxiosRequestConfig): AxiosPromise<SelfUpdateUser> {
            return localVarFp.updateCurrentUser(requestParameters.id, requestParameters.patchedSelfUpdateUserRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createViewedLesson operation in FrontofficeApi.
 * @export
 * @interface FrontofficeApiCreateViewedLessonRequest
 */
export interface FrontofficeApiCreateViewedLessonRequest {
    /**
     * 
     * @type {ViewedLessonCreateRequest}
     * @memberof FrontofficeApiCreateViewedLesson
     */
    readonly viewedLessonCreateRequest: ViewedLessonCreateRequest
}

/**
 * Request parameters for createViewedProblem operation in FrontofficeApi.
 * @export
 * @interface FrontofficeApiCreateViewedProblemRequest
 */
export interface FrontofficeApiCreateViewedProblemRequest {
    /**
     * 
     * @type {ViewedProblemCreateRequest}
     * @memberof FrontofficeApiCreateViewedProblem
     */
    readonly viewedProblemCreateRequest: ViewedProblemCreateRequest
}

/**
 * Request parameters for frontofficeApiCourseModuleList operation in FrontofficeApi.
 * @export
 * @interface FrontofficeApiFrontofficeApiCourseModuleListRequest
 */
export interface FrontofficeApiFrontofficeApiCourseModuleListRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof FrontofficeApiFrontofficeApiCourseModuleList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof FrontofficeApiFrontofficeApiCourseModuleList
     */
    readonly offset?: number
}

/**
 * Request parameters for frontofficeApiCurrentUserCreate operation in FrontofficeApi.
 * @export
 * @interface FrontofficeApiFrontofficeApiCurrentUserCreateRequest
 */
export interface FrontofficeApiFrontofficeApiCurrentUserCreateRequest {
    /**
     * 
     * @type {SelfRetrieveUserRequest}
     * @memberof FrontofficeApiFrontofficeApiCurrentUserCreate
     */
    readonly selfRetrieveUserRequest: SelfRetrieveUserRequest
}

/**
 * Request parameters for frontofficeApiCurrentUserDestroy operation in FrontofficeApi.
 * @export
 * @interface FrontofficeApiFrontofficeApiCurrentUserDestroyRequest
 */
export interface FrontofficeApiFrontofficeApiCurrentUserDestroyRequest {
    /**
     * A unique integer value identifying this user.
     * @type {number}
     * @memberof FrontofficeApiFrontofficeApiCurrentUserDestroy
     */
    readonly id: number
}

/**
 * Request parameters for frontofficeApiCurrentUserList operation in FrontofficeApi.
 * @export
 * @interface FrontofficeApiFrontofficeApiCurrentUserListRequest
 */
export interface FrontofficeApiFrontofficeApiCurrentUserListRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof FrontofficeApiFrontofficeApiCurrentUserList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof FrontofficeApiFrontofficeApiCurrentUserList
     */
    readonly offset?: number
}

/**
 * Request parameters for frontofficeApiCurrentUserUpdate operation in FrontofficeApi.
 * @export
 * @interface FrontofficeApiFrontofficeApiCurrentUserUpdateRequest
 */
export interface FrontofficeApiFrontofficeApiCurrentUserUpdateRequest {
    /**
     * A unique integer value identifying this user.
     * @type {number}
     * @memberof FrontofficeApiFrontofficeApiCurrentUserUpdate
     */
    readonly id: number

    /**
     * 
     * @type {SelfRetrieveUserRequest}
     * @memberof FrontofficeApiFrontofficeApiCurrentUserUpdate
     */
    readonly selfRetrieveUserRequest: SelfRetrieveUserRequest
}

/**
 * Request parameters for frontofficeApiPageList operation in FrontofficeApi.
 * @export
 * @interface FrontofficeApiFrontofficeApiPageListRequest
 */
export interface FrontofficeApiFrontofficeApiPageListRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof FrontofficeApiFrontofficeApiPageList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof FrontofficeApiFrontofficeApiPageList
     */
    readonly offset?: number
}

/**
 * Request parameters for frontofficeApiProblemSourceDashboardRetrieve operation in FrontofficeApi.
 * @export
 * @interface FrontofficeApiFrontofficeApiProblemSourceDashboardRetrieveRequest
 */
export interface FrontofficeApiFrontofficeApiProblemSourceDashboardRetrieveRequest {
    /**
     * A unique integer value identifying this problem source.
     * @type {number}
     * @memberof FrontofficeApiFrontofficeApiProblemSourceDashboardRetrieve
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof FrontofficeApiFrontofficeApiProblemSourceDashboardRetrieve
     */
    readonly contentType?: string

    /**
     * 
     * @type {boolean}
     * @memberof FrontofficeApiFrontofficeApiProblemSourceDashboardRetrieve
     */
    readonly inPreviewPanel?: boolean

    /**
     * 
     * @type {string}
     * @memberof FrontofficeApiFrontofficeApiProblemSourceDashboardRetrieve
     */
    readonly token?: string
}

/**
 * Request parameters for frontofficeApiProblemSourceExamRetrieve operation in FrontofficeApi.
 * @export
 * @interface FrontofficeApiFrontofficeApiProblemSourceExamRetrieveRequest
 */
export interface FrontofficeApiFrontofficeApiProblemSourceExamRetrieveRequest {
    /**
     * A unique integer value identifying this problem source.
     * @type {number}
     * @memberof FrontofficeApiFrontofficeApiProblemSourceExamRetrieve
     */
    readonly id: number
}

/**
 * Request parameters for frontofficeApiProblemSourcePracticeRetrieve operation in FrontofficeApi.
 * @export
 * @interface FrontofficeApiFrontofficeApiProblemSourcePracticeRetrieveRequest
 */
export interface FrontofficeApiFrontofficeApiProblemSourcePracticeRetrieveRequest {
    /**
     * A unique integer value identifying this problem source.
     * @type {number}
     * @memberof FrontofficeApiFrontofficeApiProblemSourcePracticeRetrieve
     */
    readonly id: number
}

/**
 * Request parameters for frontofficeApiViewedLessonDestroy operation in FrontofficeApi.
 * @export
 * @interface FrontofficeApiFrontofficeApiViewedLessonDestroyRequest
 */
export interface FrontofficeApiFrontofficeApiViewedLessonDestroyRequest {
    /**
     * A unique integer value identifying this Viewed Lesson.
     * @type {number}
     * @memberof FrontofficeApiFrontofficeApiViewedLessonDestroy
     */
    readonly id: number
}

/**
 * Request parameters for frontofficeApiViewedLessonPartialUpdate operation in FrontofficeApi.
 * @export
 * @interface FrontofficeApiFrontofficeApiViewedLessonPartialUpdateRequest
 */
export interface FrontofficeApiFrontofficeApiViewedLessonPartialUpdateRequest {
    /**
     * A unique integer value identifying this Viewed Lesson.
     * @type {number}
     * @memberof FrontofficeApiFrontofficeApiViewedLessonPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {PatchedLastViewedLessonRetrieveRequest}
     * @memberof FrontofficeApiFrontofficeApiViewedLessonPartialUpdate
     */
    readonly patchedLastViewedLessonRetrieveRequest?: PatchedLastViewedLessonRetrieveRequest
}

/**
 * Request parameters for frontofficeApiViewedLessonRetrieve operation in FrontofficeApi.
 * @export
 * @interface FrontofficeApiFrontofficeApiViewedLessonRetrieveRequest
 */
export interface FrontofficeApiFrontofficeApiViewedLessonRetrieveRequest {
    /**
     * A unique integer value identifying this Viewed Lesson.
     * @type {number}
     * @memberof FrontofficeApiFrontofficeApiViewedLessonRetrieve
     */
    readonly id: number
}

/**
 * Request parameters for frontofficeApiViewedLessonUpdate operation in FrontofficeApi.
 * @export
 * @interface FrontofficeApiFrontofficeApiViewedLessonUpdateRequest
 */
export interface FrontofficeApiFrontofficeApiViewedLessonUpdateRequest {
    /**
     * A unique integer value identifying this Viewed Lesson.
     * @type {number}
     * @memberof FrontofficeApiFrontofficeApiViewedLessonUpdate
     */
    readonly id: number

    /**
     * 
     * @type {LastViewedLessonRetrieveRequest}
     * @memberof FrontofficeApiFrontofficeApiViewedLessonUpdate
     */
    readonly lastViewedLessonRetrieveRequest: LastViewedLessonRetrieveRequest
}

/**
 * Request parameters for frontofficeApiViewedProblemDestroy operation in FrontofficeApi.
 * @export
 * @interface FrontofficeApiFrontofficeApiViewedProblemDestroyRequest
 */
export interface FrontofficeApiFrontofficeApiViewedProblemDestroyRequest {
    /**
     * A unique integer value identifying this Viewed Problem.
     * @type {number}
     * @memberof FrontofficeApiFrontofficeApiViewedProblemDestroy
     */
    readonly id: number
}

/**
 * Request parameters for frontofficeApiViewedProblemPartialUpdate operation in FrontofficeApi.
 * @export
 * @interface FrontofficeApiFrontofficeApiViewedProblemPartialUpdateRequest
 */
export interface FrontofficeApiFrontofficeApiViewedProblemPartialUpdateRequest {
    /**
     * A unique integer value identifying this Viewed Problem.
     * @type {number}
     * @memberof FrontofficeApiFrontofficeApiViewedProblemPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {PatchedViewedProblemListRequest}
     * @memberof FrontofficeApiFrontofficeApiViewedProblemPartialUpdate
     */
    readonly patchedViewedProblemListRequest?: PatchedViewedProblemListRequest
}

/**
 * Request parameters for frontofficeApiViewedProblemRetrieve operation in FrontofficeApi.
 * @export
 * @interface FrontofficeApiFrontofficeApiViewedProblemRetrieveRequest
 */
export interface FrontofficeApiFrontofficeApiViewedProblemRetrieveRequest {
    /**
     * A unique integer value identifying this Viewed Problem.
     * @type {number}
     * @memberof FrontofficeApiFrontofficeApiViewedProblemRetrieve
     */
    readonly id: number
}

/**
 * Request parameters for frontofficeApiViewedProblemUpdate operation in FrontofficeApi.
 * @export
 * @interface FrontofficeApiFrontofficeApiViewedProblemUpdateRequest
 */
export interface FrontofficeApiFrontofficeApiViewedProblemUpdateRequest {
    /**
     * A unique integer value identifying this Viewed Problem.
     * @type {number}
     * @memberof FrontofficeApiFrontofficeApiViewedProblemUpdate
     */
    readonly id: number

    /**
     * 
     * @type {ViewedProblemListRequest}
     * @memberof FrontofficeApiFrontofficeApiViewedProblemUpdate
     */
    readonly viewedProblemListRequest: ViewedProblemListRequest
}

/**
 * Request parameters for getCourseModule operation in FrontofficeApi.
 * @export
 * @interface FrontofficeApiGetCourseModuleRequest
 */
export interface FrontofficeApiGetCourseModuleRequest {
    /**
     * A unique integer value identifying this course module.
     * @type {number}
     * @memberof FrontofficeApiGetCourseModule
     */
    readonly id: number
}

/**
 * Request parameters for getCurrentUser operation in FrontofficeApi.
 * @export
 * @interface FrontofficeApiGetCurrentUserRequest
 */
export interface FrontofficeApiGetCurrentUserRequest {
    /**
     * A unique integer value identifying this user.
     * @type {number}
     * @memberof FrontofficeApiGetCurrentUser
     */
    readonly id: number
}

/**
 * Request parameters for getDigitalTextbookSectionListing operation in FrontofficeApi.
 * @export
 * @interface FrontofficeApiGetDigitalTextbookSectionListingRequest
 */
export interface FrontofficeApiGetDigitalTextbookSectionListingRequest {
    /**
     * Course id
     * @type {number}
     * @memberof FrontofficeApiGetDigitalTextbookSectionListing
     */
    readonly courseId: number

    /**
     * A unique integer value identifying this digital textbook section listing.
     * @type {number}
     * @memberof FrontofficeApiGetDigitalTextbookSectionListing
     */
    readonly id: number
}

/**
 * Request parameters for getFrontofficePage operation in FrontofficeApi.
 * @export
 * @interface FrontofficeApiGetFrontofficePageRequest
 */
export interface FrontofficeApiGetFrontofficePageRequest {
    /**
     * A unique integer value identifying this frontoffice page.
     * @type {number}
     * @memberof FrontofficeApiGetFrontofficePage
     */
    readonly id: number
}

/**
 * Request parameters for getLastViewedLessonInCourse operation in FrontofficeApi.
 * @export
 * @interface FrontofficeApiGetLastViewedLessonInCourseRequest
 */
export interface FrontofficeApiGetLastViewedLessonInCourseRequest {
    /**
     * 
     * @type {number}
     * @memberof FrontofficeApiGetLastViewedLessonInCourse
     */
    readonly course: number
}

/**
 * Request parameters for getLastViewedLessonInCourseModule operation in FrontofficeApi.
 * @export
 * @interface FrontofficeApiGetLastViewedLessonInCourseModuleRequest
 */
export interface FrontofficeApiGetLastViewedLessonInCourseModuleRequest {
    /**
     * 
     * @type {number}
     * @memberof FrontofficeApiGetLastViewedLessonInCourseModule
     */
    readonly courseModule: number
}

/**
 * Request parameters for getLesson operation in FrontofficeApi.
 * @export
 * @interface FrontofficeApiGetLessonRequest
 */
export interface FrontofficeApiGetLessonRequest {
    /**
     * A unique integer value identifying this Lesson.
     * @type {number}
     * @memberof FrontofficeApiGetLesson
     */
    readonly id: number
}

/**
 * Request parameters for getLessonList operation in FrontofficeApi.
 * @export
 * @interface FrontofficeApiGetLessonListRequest
 */
export interface FrontofficeApiGetLessonListRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof FrontofficeApiGetLessonList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof FrontofficeApiGetLessonList
     */
    readonly offset?: number
}

/**
 * Request parameters for getProblem2 operation in FrontofficeApi.
 * @export
 * @interface FrontofficeApiGetProblem2Request
 */
export interface FrontofficeApiGetProblem2Request {
    /**
     * A unique integer value identifying this problem.
     * @type {number}
     * @memberof FrontofficeApiGetProblem2
     */
    readonly id: number
}

/**
 * Request parameters for getProblemList operation in FrontofficeApi.
 * @export
 * @interface FrontofficeApiGetProblemListRequest
 */
export interface FrontofficeApiGetProblemListRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof FrontofficeApiGetProblemList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof FrontofficeApiGetProblemList
     */
    readonly offset?: number

    /**
     * * &#x60;free_for_registered&#x60; - Free for registered user * &#x60;free_preview&#x60; - Free preview * &#x60;available&#x60; - Available * &#x60;disabled&#x60; - Disabled * &#x60;hidden&#x60; - Hidden
     * @type {'available' | 'disabled' | 'free_for_registered' | 'free_preview' | 'hidden'}
     * @memberof FrontofficeApiGetProblemList
     */
    readonly visibilityStatus?: GetProblemListVisibilityStatusEnum
}

/**
 * Request parameters for getViewedLessonList operation in FrontofficeApi.
 * @export
 * @interface FrontofficeApiGetViewedLessonListRequest
 */
export interface FrontofficeApiGetViewedLessonListRequest {
    /**
     * 
     * @type {number}
     * @memberof FrontofficeApiGetViewedLessonList
     */
    readonly courseModule: number

    /**
     * 
     * @type {number}
     * @memberof FrontofficeApiGetViewedLessonList
     */
    readonly course?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof FrontofficeApiGetViewedLessonList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof FrontofficeApiGetViewedLessonList
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof FrontofficeApiGetViewedLessonList
     */
    readonly user?: number
}

/**
 * Request parameters for getViewedProblemList operation in FrontofficeApi.
 * @export
 * @interface FrontofficeApiGetViewedProblemListRequest
 */
export interface FrontofficeApiGetViewedProblemListRequest {
    /**
     * 
     * @type {number}
     * @memberof FrontofficeApiGetViewedProblemList
     */
    readonly digitalTextbookSectionListing?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof FrontofficeApiGetViewedProblemList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof FrontofficeApiGetViewedProblemList
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof FrontofficeApiGetViewedProblemList
     */
    readonly problemSource?: number

    /**
     * 
     * @type {number}
     * @memberof FrontofficeApiGetViewedProblemList
     */
    readonly user?: number
}

/**
 * Request parameters for updateCurrentUser operation in FrontofficeApi.
 * @export
 * @interface FrontofficeApiUpdateCurrentUserRequest
 */
export interface FrontofficeApiUpdateCurrentUserRequest {
    /**
     * A unique integer value identifying this user.
     * @type {number}
     * @memberof FrontofficeApiUpdateCurrentUser
     */
    readonly id: number

    /**
     * 
     * @type {PatchedSelfUpdateUserRequest}
     * @memberof FrontofficeApiUpdateCurrentUser
     */
    readonly patchedSelfUpdateUserRequest?: PatchedSelfUpdateUserRequest
}

/**
 * FrontofficeApi - object-oriented interface
 * @export
 * @class FrontofficeApi
 * @extends {BaseAPI}
 */
export class FrontofficeApi extends BaseAPI {
    /**
     * 
     * @param {FrontofficeApiCreateViewedLessonRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontofficeApi
     */
    public createViewedLesson(requestParameters: FrontofficeApiCreateViewedLessonRequest, options?: RawAxiosRequestConfig) {
        return FrontofficeApiFp(this.configuration).createViewedLesson(requestParameters.viewedLessonCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FrontofficeApiCreateViewedProblemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontofficeApi
     */
    public createViewedProblem(requestParameters: FrontofficeApiCreateViewedProblemRequest, options?: RawAxiosRequestConfig) {
        return FrontofficeApiFp(this.configuration).createViewedProblem(requestParameters.viewedProblemCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FrontofficeApiFrontofficeApiCourseModuleListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontofficeApi
     */
    public frontofficeApiCourseModuleList(requestParameters: FrontofficeApiFrontofficeApiCourseModuleListRequest = {}, options?: RawAxiosRequestConfig) {
        return FrontofficeApiFp(this.configuration).frontofficeApiCourseModuleList(requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontofficeApi
     */
    public frontofficeApiCourseModulePreviewRetrieve(options?: RawAxiosRequestConfig) {
        return FrontofficeApiFp(this.configuration).frontofficeApiCourseModulePreviewRetrieve(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FrontofficeApiFrontofficeApiCurrentUserCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontofficeApi
     */
    public frontofficeApiCurrentUserCreate(requestParameters: FrontofficeApiFrontofficeApiCurrentUserCreateRequest, options?: RawAxiosRequestConfig) {
        return FrontofficeApiFp(this.configuration).frontofficeApiCurrentUserCreate(requestParameters.selfRetrieveUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FrontofficeApiFrontofficeApiCurrentUserDestroyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontofficeApi
     */
    public frontofficeApiCurrentUserDestroy(requestParameters: FrontofficeApiFrontofficeApiCurrentUserDestroyRequest, options?: RawAxiosRequestConfig) {
        return FrontofficeApiFp(this.configuration).frontofficeApiCurrentUserDestroy(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FrontofficeApiFrontofficeApiCurrentUserListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontofficeApi
     */
    public frontofficeApiCurrentUserList(requestParameters: FrontofficeApiFrontofficeApiCurrentUserListRequest = {}, options?: RawAxiosRequestConfig) {
        return FrontofficeApiFp(this.configuration).frontofficeApiCurrentUserList(requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FrontofficeApiFrontofficeApiCurrentUserUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontofficeApi
     */
    public frontofficeApiCurrentUserUpdate(requestParameters: FrontofficeApiFrontofficeApiCurrentUserUpdateRequest, options?: RawAxiosRequestConfig) {
        return FrontofficeApiFp(this.configuration).frontofficeApiCurrentUserUpdate(requestParameters.id, requestParameters.selfRetrieveUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FrontofficeApiFrontofficeApiPageListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontofficeApi
     */
    public frontofficeApiPageList(requestParameters: FrontofficeApiFrontofficeApiPageListRequest = {}, options?: RawAxiosRequestConfig) {
        return FrontofficeApiFp(this.configuration).frontofficeApiPageList(requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontofficeApi
     */
    public frontofficeApiProblemSourceDashboardPreviewRetrieve(options?: RawAxiosRequestConfig) {
        return FrontofficeApiFp(this.configuration).frontofficeApiProblemSourceDashboardPreviewRetrieve(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FrontofficeApiFrontofficeApiProblemSourceDashboardRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontofficeApi
     */
    public frontofficeApiProblemSourceDashboardRetrieve(requestParameters: FrontofficeApiFrontofficeApiProblemSourceDashboardRetrieveRequest, options?: RawAxiosRequestConfig) {
        return FrontofficeApiFp(this.configuration).frontofficeApiProblemSourceDashboardRetrieve(requestParameters.id, requestParameters.contentType, requestParameters.inPreviewPanel, requestParameters.token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontofficeApi
     */
    public frontofficeApiProblemSourceExamPreviewRetrieve(options?: RawAxiosRequestConfig) {
        return FrontofficeApiFp(this.configuration).frontofficeApiProblemSourceExamPreviewRetrieve(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FrontofficeApiFrontofficeApiProblemSourceExamRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontofficeApi
     */
    public frontofficeApiProblemSourceExamRetrieve(requestParameters: FrontofficeApiFrontofficeApiProblemSourceExamRetrieveRequest, options?: RawAxiosRequestConfig) {
        return FrontofficeApiFp(this.configuration).frontofficeApiProblemSourceExamRetrieve(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontofficeApi
     */
    public frontofficeApiProblemSourcePracticePreviewRetrieve(options?: RawAxiosRequestConfig) {
        return FrontofficeApiFp(this.configuration).frontofficeApiProblemSourcePracticePreviewRetrieve(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FrontofficeApiFrontofficeApiProblemSourcePracticeRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontofficeApi
     */
    public frontofficeApiProblemSourcePracticeRetrieve(requestParameters: FrontofficeApiFrontofficeApiProblemSourcePracticeRetrieveRequest, options?: RawAxiosRequestConfig) {
        return FrontofficeApiFp(this.configuration).frontofficeApiProblemSourcePracticeRetrieve(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FrontofficeApiFrontofficeApiViewedLessonDestroyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontofficeApi
     */
    public frontofficeApiViewedLessonDestroy(requestParameters: FrontofficeApiFrontofficeApiViewedLessonDestroyRequest, options?: RawAxiosRequestConfig) {
        return FrontofficeApiFp(this.configuration).frontofficeApiViewedLessonDestroy(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FrontofficeApiFrontofficeApiViewedLessonPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontofficeApi
     */
    public frontofficeApiViewedLessonPartialUpdate(requestParameters: FrontofficeApiFrontofficeApiViewedLessonPartialUpdateRequest, options?: RawAxiosRequestConfig) {
        return FrontofficeApiFp(this.configuration).frontofficeApiViewedLessonPartialUpdate(requestParameters.id, requestParameters.patchedLastViewedLessonRetrieveRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FrontofficeApiFrontofficeApiViewedLessonRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontofficeApi
     */
    public frontofficeApiViewedLessonRetrieve(requestParameters: FrontofficeApiFrontofficeApiViewedLessonRetrieveRequest, options?: RawAxiosRequestConfig) {
        return FrontofficeApiFp(this.configuration).frontofficeApiViewedLessonRetrieve(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FrontofficeApiFrontofficeApiViewedLessonUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontofficeApi
     */
    public frontofficeApiViewedLessonUpdate(requestParameters: FrontofficeApiFrontofficeApiViewedLessonUpdateRequest, options?: RawAxiosRequestConfig) {
        return FrontofficeApiFp(this.configuration).frontofficeApiViewedLessonUpdate(requestParameters.id, requestParameters.lastViewedLessonRetrieveRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FrontofficeApiFrontofficeApiViewedProblemDestroyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontofficeApi
     */
    public frontofficeApiViewedProblemDestroy(requestParameters: FrontofficeApiFrontofficeApiViewedProblemDestroyRequest, options?: RawAxiosRequestConfig) {
        return FrontofficeApiFp(this.configuration).frontofficeApiViewedProblemDestroy(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FrontofficeApiFrontofficeApiViewedProblemPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontofficeApi
     */
    public frontofficeApiViewedProblemPartialUpdate(requestParameters: FrontofficeApiFrontofficeApiViewedProblemPartialUpdateRequest, options?: RawAxiosRequestConfig) {
        return FrontofficeApiFp(this.configuration).frontofficeApiViewedProblemPartialUpdate(requestParameters.id, requestParameters.patchedViewedProblemListRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FrontofficeApiFrontofficeApiViewedProblemRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontofficeApi
     */
    public frontofficeApiViewedProblemRetrieve(requestParameters: FrontofficeApiFrontofficeApiViewedProblemRetrieveRequest, options?: RawAxiosRequestConfig) {
        return FrontofficeApiFp(this.configuration).frontofficeApiViewedProblemRetrieve(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FrontofficeApiFrontofficeApiViewedProblemUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontofficeApi
     */
    public frontofficeApiViewedProblemUpdate(requestParameters: FrontofficeApiFrontofficeApiViewedProblemUpdateRequest, options?: RawAxiosRequestConfig) {
        return FrontofficeApiFp(this.configuration).frontofficeApiViewedProblemUpdate(requestParameters.id, requestParameters.viewedProblemListRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FrontofficeApiGetCourseModuleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontofficeApi
     */
    public getCourseModule(requestParameters: FrontofficeApiGetCourseModuleRequest, options?: RawAxiosRequestConfig) {
        return FrontofficeApiFp(this.configuration).getCourseModule(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FrontofficeApiGetCurrentUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontofficeApi
     */
    public getCurrentUser(requestParameters: FrontofficeApiGetCurrentUserRequest, options?: RawAxiosRequestConfig) {
        return FrontofficeApiFp(this.configuration).getCurrentUser(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FrontofficeApiGetDigitalTextbookSectionListingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontofficeApi
     */
    public getDigitalTextbookSectionListing(requestParameters: FrontofficeApiGetDigitalTextbookSectionListingRequest, options?: RawAxiosRequestConfig) {
        return FrontofficeApiFp(this.configuration).getDigitalTextbookSectionListing(requestParameters.courseId, requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FrontofficeApiGetFrontofficePageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontofficeApi
     */
    public getFrontofficePage(requestParameters: FrontofficeApiGetFrontofficePageRequest, options?: RawAxiosRequestConfig) {
        return FrontofficeApiFp(this.configuration).getFrontofficePage(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontofficeApi
     */
    public getHighSchoolList(options?: RawAxiosRequestConfig) {
        return FrontofficeApiFp(this.configuration).getHighSchoolList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontofficeApi
     */
    public getLastViewedCourseModule(options?: RawAxiosRequestConfig) {
        return FrontofficeApiFp(this.configuration).getLastViewedCourseModule(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FrontofficeApiGetLastViewedLessonInCourseRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontofficeApi
     */
    public getLastViewedLessonInCourse(requestParameters: FrontofficeApiGetLastViewedLessonInCourseRequest, options?: RawAxiosRequestConfig) {
        return FrontofficeApiFp(this.configuration).getLastViewedLessonInCourse(requestParameters.course, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FrontofficeApiGetLastViewedLessonInCourseModuleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontofficeApi
     */
    public getLastViewedLessonInCourseModule(requestParameters: FrontofficeApiGetLastViewedLessonInCourseModuleRequest, options?: RawAxiosRequestConfig) {
        return FrontofficeApiFp(this.configuration).getLastViewedLessonInCourseModule(requestParameters.courseModule, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FrontofficeApiGetLessonRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontofficeApi
     */
    public getLesson(requestParameters: FrontofficeApiGetLessonRequest, options?: RawAxiosRequestConfig) {
        return FrontofficeApiFp(this.configuration).getLesson(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FrontofficeApiGetLessonListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontofficeApi
     */
    public getLessonList(requestParameters: FrontofficeApiGetLessonListRequest = {}, options?: RawAxiosRequestConfig) {
        return FrontofficeApiFp(this.configuration).getLessonList(requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FrontofficeApiGetProblem2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontofficeApi
     */
    public getProblem2(requestParameters: FrontofficeApiGetProblem2Request, options?: RawAxiosRequestConfig) {
        return FrontofficeApiFp(this.configuration).getProblem2(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FrontofficeApiGetProblemListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontofficeApi
     */
    public getProblemList(requestParameters: FrontofficeApiGetProblemListRequest = {}, options?: RawAxiosRequestConfig) {
        return FrontofficeApiFp(this.configuration).getProblemList(requestParameters.limit, requestParameters.offset, requestParameters.visibilityStatus, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontofficeApi
     */
    public getUniversityList(options?: RawAxiosRequestConfig) {
        return FrontofficeApiFp(this.configuration).getUniversityList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontofficeApi
     */
    public getUniversityProgramsList(options?: RawAxiosRequestConfig) {
        return FrontofficeApiFp(this.configuration).getUniversityProgramsList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FrontofficeApiGetViewedLessonListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontofficeApi
     */
    public getViewedLessonList(requestParameters: FrontofficeApiGetViewedLessonListRequest, options?: RawAxiosRequestConfig) {
        return FrontofficeApiFp(this.configuration).getViewedLessonList(requestParameters.courseModule, requestParameters.course, requestParameters.limit, requestParameters.offset, requestParameters.user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FrontofficeApiGetViewedProblemListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontofficeApi
     */
    public getViewedProblemList(requestParameters: FrontofficeApiGetViewedProblemListRequest = {}, options?: RawAxiosRequestConfig) {
        return FrontofficeApiFp(this.configuration).getViewedProblemList(requestParameters.digitalTextbookSectionListing, requestParameters.limit, requestParameters.offset, requestParameters.problemSource, requestParameters.user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FrontofficeApiUpdateCurrentUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontofficeApi
     */
    public updateCurrentUser(requestParameters: FrontofficeApiUpdateCurrentUserRequest, options?: RawAxiosRequestConfig) {
        return FrontofficeApiFp(this.configuration).updateCurrentUser(requestParameters.id, requestParameters.patchedSelfUpdateUserRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetProblemListVisibilityStatusEnum = {
    Available: 'available',
    Disabled: 'disabled',
    FreeForRegistered: 'free_for_registered',
    FreePreview: 'free_preview',
    Hidden: 'hidden'
} as const;
export type GetProblemListVisibilityStatusEnum = typeof GetProblemListVisibilityStatusEnum[keyof typeof GetProblemListVisibilityStatusEnum];
