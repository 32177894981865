/* tslint:disable */
/* eslint-disable */
/**
 * Gradivo API
 * API endpoints for Gradivo.hr
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * * `FORMATTING` - Formatting * `FORMATTING_FAILED` - Formatting Failed * `FORMATTING_SUCCESS` - Formatting Success * `MAPPING` - Mapping * `MAPPING_FAILED` - Mapping Failed * `MAPPING_SUCCESS` - Mapping Success
 * @export
 * @enum {string}
 */

export const ProblemsImportSolutionsMappingStatusEnum = {
    Formatting: 'FORMATTING',
    FormattingFailed: 'FORMATTING_FAILED',
    FormattingSuccess: 'FORMATTING_SUCCESS',
    Mapping: 'MAPPING',
    MappingFailed: 'MAPPING_FAILED',
    MappingSuccess: 'MAPPING_SUCCESS'
} as const;

export type ProblemsImportSolutionsMappingStatusEnum = typeof ProblemsImportSolutionsMappingStatusEnum[keyof typeof ProblemsImportSolutionsMappingStatusEnum];



