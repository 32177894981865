/* tslint:disable */
/* eslint-disable */
/**
 * Gradivo API
 * API endpoints for Gradivo.hr
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { CourseCategory } from '../models';
// @ts-ignore
import type { CourseFrontoffice } from '../models';
// @ts-ignore
import type { CourseWithCourseModulesFrontoffice } from '../models';
// @ts-ignore
import type { CourseWithDigitalTextbookFrontoffice } from '../models';
// @ts-ignore
import type { CourseWithProblemSourceListingsFrontoffice } from '../models';
// @ts-ignore
import type { CourseWithVideoListingFrontoffice } from '../models';
// @ts-ignore
import type { DigitalTextbook } from '../models';
// @ts-ignore
import type { DiscardQuizAttemptResponse } from '../models';
// @ts-ignore
import type { FinishQuizAttemptResponse } from '../models';
// @ts-ignore
import type { MaturaDownloaderGenerateZipRequestRequest } from '../models';
// @ts-ignore
import type { MaturaDownloaderGenerateZipResponse } from '../models';
// @ts-ignore
import type { PaginatedDigitalTextbookListList } from '../models';
// @ts-ignore
import type { PaginatedQuizAttemptListList } from '../models';
// @ts-ignore
import type { PaginatedQuizList } from '../models';
// @ts-ignore
import type { PaginatedQuizProblemAttemptedAnswerList } from '../models';
// @ts-ignore
import type { PaginatedUserDataFormAttemptListList } from '../models';
// @ts-ignore
import type { PaginatedUserDataFormListList } from '../models';
// @ts-ignore
import type { PaginatedUserDataFormStepList } from '../models';
// @ts-ignore
import type { PaginatedUserList } from '../models';
// @ts-ignore
import type { PatchedQuizAttemptUpdateRequestRequest } from '../models';
// @ts-ignore
import type { PatchedQuizProblemAttemptedAnswerRequest } from '../models';
// @ts-ignore
import type { PatchedUserDataFormAttemptRequest } from '../models';
// @ts-ignore
import type { Quiz } from '../models';
// @ts-ignore
import type { QuizAttempt } from '../models';
// @ts-ignore
import type { QuizAttemptCreateRequestRequest } from '../models';
// @ts-ignore
import type { QuizAttemptCreateResponse } from '../models';
// @ts-ignore
import type { QuizAttemptRequest } from '../models';
// @ts-ignore
import type { QuizAttemptResults } from '../models';
// @ts-ignore
import type { QuizProblemAttemptedAnswer } from '../models';
// @ts-ignore
import type { QuizProblemAttemptedAnswerRequest } from '../models';
// @ts-ignore
import type { QuizProblemAttemptedAnswerRequestRequest } from '../models';
// @ts-ignore
import type { QuizQuizSnapshot } from '../models';
// @ts-ignore
import type { User } from '../models';
// @ts-ignore
import type { UserDataForm } from '../models';
// @ts-ignore
import type { UserDataFormAttempt } from '../models';
// @ts-ignore
import type { UserDataFormAttemptRequest } from '../models';
// @ts-ignore
import type { UserDataFormResponses } from '../models';
// @ts-ignore
import type { UserDataFormStep } from '../models';
// @ts-ignore
import type { VimeoFoldersResponse } from '../models';
// @ts-ignore
import type { VimeoUploadLinkRequestRequest } from '../models';
// @ts-ignore
import type { VimeoUploadLinkResponse } from '../models';
/**
 * SharedApi - axios parameter creator
 * @export
 */
export const SharedApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {QuizProblemAttemptedAnswerRequestRequest} quizProblemAttemptedAnswerRequestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateAttemptedAnswer: async (quizProblemAttemptedAnswerRequestRequest: QuizProblemAttemptedAnswerRequestRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'quizProblemAttemptedAnswerRequestRequest' is not null or undefined
            assertParamExists('createOrUpdateAttemptedAnswer', 'quizProblemAttemptedAnswerRequestRequest', quizProblemAttemptedAnswerRequestRequest)
            const localVarPath = `/wt/shared/api/quiz-problem-attempted-answer/create_or_update/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(quizProblemAttemptedAnswerRequestRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {QuizAttemptCreateRequestRequest} quizAttemptCreateRequestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createQuizAttempt: async (quizAttemptCreateRequestRequest: QuizAttemptCreateRequestRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'quizAttemptCreateRequestRequest' is not null or undefined
            assertParamExists('createQuizAttempt', 'quizAttemptCreateRequestRequest', quizAttemptCreateRequestRequest)
            const localVarPath = `/wt/shared/api/quiz-attempt/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(quizAttemptCreateRequestRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Quiz attempt.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finishQuizAttempt: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('finishQuizAttempt', 'id', id)
            const localVarPath = `/wt/shared/api/quiz-attempt/{id}/discard-quiz-attempt/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Quiz attempt.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finishQuizAttempt2: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('finishQuizAttempt2', 'id', id)
            const localVarPath = `/wt/shared/api/quiz-attempt/{id}/finish-quiz-attempt/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Generates a zip file with the matura exam problem sources.
         * @param {MaturaDownloaderGenerateZipRequestRequest} maturaDownloaderGenerateZipRequestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateMaturaDownloaderZip: async (maturaDownloaderGenerateZipRequestRequest: MaturaDownloaderGenerateZipRequestRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'maturaDownloaderGenerateZipRequestRequest' is not null or undefined
            assertParamExists('generateMaturaDownloaderZip', 'maturaDownloaderGenerateZipRequestRequest', maturaDownloaderGenerateZipRequestRequest)
            const localVarPath = `/wt/shared/api/matura-downloader/generate_zip/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(maturaDownloaderGenerateZipRequestRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [quiz] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveQuizAttempt: async (quiz?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/shared/api/quiz-attempt/retrieve-active/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (quiz !== undefined) {
                localVarQueryParameter['quiz'] = quiz;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this course.
         * @param {string} [contentType] 
         * @param {boolean} [inPreviewPanel] 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourse: async (id: number, contentType?: string, inPreviewPanel?: boolean, token?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCourse', 'id', id)
            const localVarPath = `/wt/shared/api/course/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (contentType !== undefined) {
                localVarQueryParameter['content_type'] = contentType;
            }

            if (inPreviewPanel !== undefined) {
                localVarQueryParameter['in_preview_panel'] = inPreviewPanel;
            }

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this course.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseCategory: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCourseCategory', 'id', id)
            const localVarPath = `/wt/shared/api/course/{id}/course-category/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this course.
         * @param {string} [contentType] 
         * @param {boolean} [inPreviewPanel] 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseCourseModules: async (id: number, contentType?: string, inPreviewPanel?: boolean, token?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCourseCourseModules', 'id', id)
            const localVarPath = `/wt/shared/api/course/{id}/retrieve_with_course_modules/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (contentType !== undefined) {
                localVarQueryParameter['content_type'] = contentType;
            }

            if (inPreviewPanel !== undefined) {
                localVarQueryParameter['in_preview_panel'] = inPreviewPanel;
            }

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this course.
         * @param {string} [contentType] 
         * @param {boolean} [inPreviewPanel] 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseDigitalTextbook: async (id: number, contentType?: string, inPreviewPanel?: boolean, token?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCourseDigitalTextbook', 'id', id)
            const localVarPath = `/wt/shared/api/course/{id}/retrieve_with_digital_textbook/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (contentType !== undefined) {
                localVarQueryParameter['content_type'] = contentType;
            }

            if (inPreviewPanel !== undefined) {
                localVarQueryParameter['in_preview_panel'] = inPreviewPanel;
            }

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this course.
         * @param {string} [contentType] 
         * @param {boolean} [inPreviewPanel] 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseProblemSourceListings: async (id: number, contentType?: string, inPreviewPanel?: boolean, token?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCourseProblemSourceListings', 'id', id)
            const localVarPath = `/wt/shared/api/course/{id}/retrieve_with_problem_source_listings/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (contentType !== undefined) {
                localVarQueryParameter['content_type'] = contentType;
            }

            if (inPreviewPanel !== undefined) {
                localVarQueryParameter['in_preview_panel'] = inPreviewPanel;
            }

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this course.
         * @param {string} [contentType] 
         * @param {boolean} [inPreviewPanel] 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseVideoListings: async (id: number, contentType?: string, inPreviewPanel?: boolean, token?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCourseVideoListings', 'id', id)
            const localVarPath = `/wt/shared/api/course/{id}/retrieve_with_video_listings/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (contentType !== undefined) {
                localVarQueryParameter['content_type'] = contentType;
            }

            if (inPreviewPanel !== undefined) {
                localVarQueryParameter['in_preview_panel'] = inPreviewPanel;
            }

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this digital textbook.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDigitalTextbook: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDigitalTextbook', 'id', id)
            const localVarPath = `/wt/shared/api/digital-textbook/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [digitalTextbookSubject] 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDigitalTextbookList: async (digitalTextbookSubject?: number, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/shared/api/digital-textbook/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (digitalTextbookSubject !== undefined) {
                localVarQueryParameter['digital_textbook_subject'] = digitalTextbookSubject;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this quiz.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuiz: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getQuiz', 'id', id)
            const localVarPath = `/wt/shared/api/quiz/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [finishedAt] 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {number} [quiz] 
         * @param {number} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuizAttemptList: async (finishedAt?: string, limit?: number, offset?: number, quiz?: number, user?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/shared/api/quiz-attempt/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (finishedAt !== undefined) {
                localVarQueryParameter['finished_at'] = (finishedAt as any instanceof Date) ?
                    (finishedAt as any).toISOString() :
                    finishedAt;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (quiz !== undefined) {
                localVarQueryParameter['quiz'] = quiz;
            }

            if (user !== undefined) {
                localVarQueryParameter['user'] = user;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {QuizAttemptRequest} quizAttemptRequest 
         * @param {string} [finishedAt] 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {number} [quiz] 
         * @param {number} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuizAttemptListByUser: async (quizAttemptRequest: QuizAttemptRequest, finishedAt?: string, limit?: number, offset?: number, quiz?: number, user?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'quizAttemptRequest' is not null or undefined
            assertParamExists('getQuizAttemptListByUser', 'quizAttemptRequest', quizAttemptRequest)
            const localVarPath = `/wt/shared/api/quiz-attempt/list-by-customer/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (finishedAt !== undefined) {
                localVarQueryParameter['finished_at'] = (finishedAt as any instanceof Date) ?
                    (finishedAt as any).toISOString() :
                    finishedAt;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (quiz !== undefined) {
                localVarQueryParameter['quiz'] = quiz;
            }

            if (user !== undefined) {
                localVarQueryParameter['user'] = user;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(quizAttemptRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Quiz attempt.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuizAttemptResults: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getQuizAttemptResults', 'id', id)
            const localVarPath = `/wt/shared/api/quiz-attempt/{id}/retrieve-results/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this user data form.
         * @param {string} [contentType] 
         * @param {boolean} [inPreviewPanel] 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserDataForm: async (id: number, contentType?: string, inPreviewPanel?: boolean, token?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUserDataForm', 'id', id)
            const localVarPath = `/wt/shared/api/user-data-form/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (contentType !== undefined) {
                localVarQueryParameter['content_type'] = contentType;
            }

            if (inPreviewPanel !== undefined) {
                localVarQueryParameter['in_preview_panel'] = inPreviewPanel;
            }

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this User onboarding attempt.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserDataFormAttempt: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUserDataFormAttempt', 'id', id)
            const localVarPath = `/wt/shared/api/user-data-form-attempt/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {number} [userDataForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserDataFormAttemptList: async (limit?: number, offset?: number, userDataForm?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/shared/api/user-data-form-attempt/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (userDataForm !== undefined) {
                localVarQueryParameter['user_data_form'] = userDataForm;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserDataFormList: async (limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/shared/api/user-data-form/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this user data form.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserDataFormResponses: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUserDataFormResponses', 'id', id)
            const localVarPath = `/wt/shared/api/user-data-form/{id}/responses/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this user data form step.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserDataFormStep: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUserDataFormStep', 'id', id)
            const localVarPath = `/wt/shared/api/user-data-form-screen/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [fields] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVimeoFolders: async (fields?: string, page?: number, perPage?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/shared/api/vimeo/folders/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<VimeoUploadLinkRequestRequest>} vimeoUploadLinkRequestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVimeoUploadLinkBatch: async (vimeoUploadLinkRequestRequest: Array<VimeoUploadLinkRequestRequest>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vimeoUploadLinkRequestRequest' is not null or undefined
            assertParamExists('getVimeoUploadLinkBatch', 'vimeoUploadLinkRequestRequest', vimeoUploadLinkRequestRequest)
            const localVarPath = `/wt/shared/api/vimeo/upload-link-batch/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vimeoUploadLinkRequestRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send account activation email
         * @param {number} id A unique integer value identifying this user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendAccountActivationEmail: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('sendAccountActivationEmail', 'id', id)
            const localVarPath = `/wt/shared/api/user/{id}/send-activation-email/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send confirmation email
         * @param {number} id A unique integer value identifying this user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendConfirmationEmail: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('sendConfirmationEmail', 'id', id)
            const localVarPath = `/wt/shared/api/user/{id}/send-confirmation-email/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sharedApiCoursePreviewRetrieve: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/shared/api/course/preview/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Quiz attempt.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sharedApiQuizAttemptDestroy: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('sharedApiQuizAttemptDestroy', 'id', id)
            const localVarPath = `/wt/shared/api/quiz-attempt/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Quiz attempt.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sharedApiQuizAttemptRetrieve: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('sharedApiQuizAttemptRetrieve', 'id', id)
            const localVarPath = `/wt/shared/api/quiz-attempt/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Quiz attempt.
         * @param {QuizAttemptRequest} quizAttemptRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sharedApiQuizAttemptUpdate: async (id: number, quizAttemptRequest: QuizAttemptRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('sharedApiQuizAttemptUpdate', 'id', id)
            // verify required parameter 'quizAttemptRequest' is not null or undefined
            assertParamExists('sharedApiQuizAttemptUpdate', 'quizAttemptRequest', quizAttemptRequest)
            const localVarPath = `/wt/shared/api/quiz-attempt/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(quizAttemptRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sharedApiQuizList: async (limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/shared/api/quiz/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {QuizProblemAttemptedAnswerRequest} quizProblemAttemptedAnswerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sharedApiQuizProblemAttemptedAnswerCreate: async (quizProblemAttemptedAnswerRequest: QuizProblemAttemptedAnswerRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'quizProblemAttemptedAnswerRequest' is not null or undefined
            assertParamExists('sharedApiQuizProblemAttemptedAnswerCreate', 'quizProblemAttemptedAnswerRequest', quizProblemAttemptedAnswerRequest)
            const localVarPath = `/wt/shared/api/quiz-problem-attempted-answer/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(quizProblemAttemptedAnswerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this quiz problem attempted answer.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sharedApiQuizProblemAttemptedAnswerDestroy: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('sharedApiQuizProblemAttemptedAnswerDestroy', 'id', id)
            const localVarPath = `/wt/shared/api/quiz-problem-attempted-answer/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sharedApiQuizProblemAttemptedAnswerList: async (limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/shared/api/quiz-problem-attempted-answer/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this quiz problem attempted answer.
         * @param {PatchedQuizProblemAttemptedAnswerRequest} [patchedQuizProblemAttemptedAnswerRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sharedApiQuizProblemAttemptedAnswerPartialUpdate: async (id: number, patchedQuizProblemAttemptedAnswerRequest?: PatchedQuizProblemAttemptedAnswerRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('sharedApiQuizProblemAttemptedAnswerPartialUpdate', 'id', id)
            const localVarPath = `/wt/shared/api/quiz-problem-attempted-answer/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedQuizProblemAttemptedAnswerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this quiz problem attempted answer.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sharedApiQuizProblemAttemptedAnswerRetrieve: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('sharedApiQuizProblemAttemptedAnswerRetrieve', 'id', id)
            const localVarPath = `/wt/shared/api/quiz-problem-attempted-answer/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this quiz problem attempted answer.
         * @param {QuizProblemAttemptedAnswerRequest} quizProblemAttemptedAnswerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sharedApiQuizProblemAttemptedAnswerUpdate: async (id: number, quizProblemAttemptedAnswerRequest: QuizProblemAttemptedAnswerRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('sharedApiQuizProblemAttemptedAnswerUpdate', 'id', id)
            // verify required parameter 'quizProblemAttemptedAnswerRequest' is not null or undefined
            assertParamExists('sharedApiQuizProblemAttemptedAnswerUpdate', 'quizProblemAttemptedAnswerRequest', quizProblemAttemptedAnswerRequest)
            const localVarPath = `/wt/shared/api/quiz-problem-attempted-answer/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(quizProblemAttemptedAnswerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this quiz.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sharedApiQuizSnapshotRetrieve: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('sharedApiQuizSnapshotRetrieve', 'id', id)
            const localVarPath = `/wt/shared/api/quiz/{id}/snapshot/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserDataFormAttemptRequest} userDataFormAttemptRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sharedApiUserDataFormAttemptCreate: async (userDataFormAttemptRequest: UserDataFormAttemptRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userDataFormAttemptRequest' is not null or undefined
            assertParamExists('sharedApiUserDataFormAttemptCreate', 'userDataFormAttemptRequest', userDataFormAttemptRequest)
            const localVarPath = `/wt/shared/api/user-data-form-attempt/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userDataFormAttemptRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this User onboarding attempt.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sharedApiUserDataFormAttemptDestroy: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('sharedApiUserDataFormAttemptDestroy', 'id', id)
            const localVarPath = `/wt/shared/api/user-data-form-attempt/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this User onboarding attempt.
         * @param {PatchedUserDataFormAttemptRequest} [patchedUserDataFormAttemptRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sharedApiUserDataFormAttemptPartialUpdate: async (id: number, patchedUserDataFormAttemptRequest?: PatchedUserDataFormAttemptRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('sharedApiUserDataFormAttemptPartialUpdate', 'id', id)
            const localVarPath = `/wt/shared/api/user-data-form-attempt/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedUserDataFormAttemptRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this User onboarding attempt.
         * @param {UserDataFormAttemptRequest} userDataFormAttemptRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sharedApiUserDataFormAttemptUpdate: async (id: number, userDataFormAttemptRequest: UserDataFormAttemptRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('sharedApiUserDataFormAttemptUpdate', 'id', id)
            // verify required parameter 'userDataFormAttemptRequest' is not null or undefined
            assertParamExists('sharedApiUserDataFormAttemptUpdate', 'userDataFormAttemptRequest', userDataFormAttemptRequest)
            const localVarPath = `/wt/shared/api/user-data-form-attempt/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userDataFormAttemptRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sharedApiUserDataFormPreviewRetrieve: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/shared/api/user-data-form/preview/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sharedApiUserDataFormScreenList: async (limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/shared/api/user-data-form-screen/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sharedApiUserList: async (limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/shared/api/user/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sharedApiUserRetrieve: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('sharedApiUserRetrieve', 'id', id)
            const localVarPath = `/wt/shared/api/user/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sharedApiVimeoCreateFolderCreate: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/wt/shared/api/vimeo/create_folder/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserDataFormAttemptRequest} userDataFormAttemptRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrCreateUserDataFormAttempt: async (userDataFormAttemptRequest: UserDataFormAttemptRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userDataFormAttemptRequest' is not null or undefined
            assertParamExists('updateOrCreateUserDataFormAttempt', 'userDataFormAttemptRequest', userDataFormAttemptRequest)
            const localVarPath = `/wt/shared/api/user-data-form-attempt/update-or-create/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userDataFormAttemptRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Quiz attempt.
         * @param {PatchedQuizAttemptUpdateRequestRequest} [patchedQuizAttemptUpdateRequestRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateQuizAttempt: async (id: number, patchedQuizAttemptUpdateRequestRequest?: PatchedQuizAttemptUpdateRequestRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateQuizAttempt', 'id', id)
            const localVarPath = `/wt/shared/api/quiz-attempt/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedQuizAttemptUpdateRequestRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SharedApi - functional programming interface
 * @export
 */
export const SharedApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SharedApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {QuizProblemAttemptedAnswerRequestRequest} quizProblemAttemptedAnswerRequestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrUpdateAttemptedAnswer(quizProblemAttemptedAnswerRequestRequest: QuizProblemAttemptedAnswerRequestRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuizProblemAttemptedAnswer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrUpdateAttemptedAnswer(quizProblemAttemptedAnswerRequestRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.createOrUpdateAttemptedAnswer']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {QuizAttemptCreateRequestRequest} quizAttemptCreateRequestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createQuizAttempt(quizAttemptCreateRequestRequest: QuizAttemptCreateRequestRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuizAttemptCreateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createQuizAttempt(quizAttemptCreateRequestRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.createQuizAttempt']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Quiz attempt.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async finishQuizAttempt(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DiscardQuizAttemptResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.finishQuizAttempt(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.finishQuizAttempt']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Quiz attempt.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async finishQuizAttempt2(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FinishQuizAttemptResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.finishQuizAttempt2(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.finishQuizAttempt2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Generates a zip file with the matura exam problem sources.
         * @param {MaturaDownloaderGenerateZipRequestRequest} maturaDownloaderGenerateZipRequestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateMaturaDownloaderZip(maturaDownloaderGenerateZipRequestRequest: MaturaDownloaderGenerateZipRequestRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaturaDownloaderGenerateZipResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateMaturaDownloaderZip(maturaDownloaderGenerateZipRequestRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.generateMaturaDownloaderZip']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [quiz] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActiveQuizAttempt(quiz?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuizAttempt>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActiveQuizAttempt(quiz, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.getActiveQuizAttempt']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this course.
         * @param {string} [contentType] 
         * @param {boolean} [inPreviewPanel] 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCourse(id: number, contentType?: string, inPreviewPanel?: boolean, token?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourseFrontoffice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCourse(id, contentType, inPreviewPanel, token, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.getCourse']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this course.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCourseCategory(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourseCategory>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCourseCategory(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.getCourseCategory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this course.
         * @param {string} [contentType] 
         * @param {boolean} [inPreviewPanel] 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCourseCourseModules(id: number, contentType?: string, inPreviewPanel?: boolean, token?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourseWithCourseModulesFrontoffice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCourseCourseModules(id, contentType, inPreviewPanel, token, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.getCourseCourseModules']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this course.
         * @param {string} [contentType] 
         * @param {boolean} [inPreviewPanel] 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCourseDigitalTextbook(id: number, contentType?: string, inPreviewPanel?: boolean, token?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourseWithDigitalTextbookFrontoffice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCourseDigitalTextbook(id, contentType, inPreviewPanel, token, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.getCourseDigitalTextbook']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this course.
         * @param {string} [contentType] 
         * @param {boolean} [inPreviewPanel] 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCourseProblemSourceListings(id: number, contentType?: string, inPreviewPanel?: boolean, token?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourseWithProblemSourceListingsFrontoffice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCourseProblemSourceListings(id, contentType, inPreviewPanel, token, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.getCourseProblemSourceListings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this course.
         * @param {string} [contentType] 
         * @param {boolean} [inPreviewPanel] 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCourseVideoListings(id: number, contentType?: string, inPreviewPanel?: boolean, token?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourseWithVideoListingFrontoffice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCourseVideoListings(id, contentType, inPreviewPanel, token, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.getCourseVideoListings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this digital textbook.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDigitalTextbook(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DigitalTextbook>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDigitalTextbook(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.getDigitalTextbook']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [digitalTextbookSubject] 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDigitalTextbookList(digitalTextbookSubject?: number, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedDigitalTextbookListList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDigitalTextbookList(digitalTextbookSubject, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.getDigitalTextbookList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this quiz.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getQuiz(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Quiz>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getQuiz(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.getQuiz']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [finishedAt] 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {number} [quiz] 
         * @param {number} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getQuizAttemptList(finishedAt?: string, limit?: number, offset?: number, quiz?: number, user?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedQuizAttemptListList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getQuizAttemptList(finishedAt, limit, offset, quiz, user, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.getQuizAttemptList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {QuizAttemptRequest} quizAttemptRequest 
         * @param {string} [finishedAt] 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {number} [quiz] 
         * @param {number} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getQuizAttemptListByUser(quizAttemptRequest: QuizAttemptRequest, finishedAt?: string, limit?: number, offset?: number, quiz?: number, user?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedQuizAttemptListList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getQuizAttemptListByUser(quizAttemptRequest, finishedAt, limit, offset, quiz, user, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.getQuizAttemptListByUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Quiz attempt.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getQuizAttemptResults(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuizAttemptResults>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getQuizAttemptResults(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.getQuizAttemptResults']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this user data form.
         * @param {string} [contentType] 
         * @param {boolean} [inPreviewPanel] 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserDataForm(id: number, contentType?: string, inPreviewPanel?: boolean, token?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDataForm>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserDataForm(id, contentType, inPreviewPanel, token, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.getUserDataForm']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this User onboarding attempt.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserDataFormAttempt(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDataFormAttempt>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserDataFormAttempt(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.getUserDataFormAttempt']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {number} [userDataForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserDataFormAttemptList(limit?: number, offset?: number, userDataForm?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedUserDataFormAttemptListList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserDataFormAttemptList(limit, offset, userDataForm, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.getUserDataFormAttemptList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserDataFormList(limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedUserDataFormListList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserDataFormList(limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.getUserDataFormList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this user data form.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserDataFormResponses(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDataFormResponses>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserDataFormResponses(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.getUserDataFormResponses']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this user data form step.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserDataFormStep(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDataFormStep>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserDataFormStep(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.getUserDataFormStep']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [fields] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVimeoFolders(fields?: string, page?: number, perPage?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VimeoFoldersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVimeoFolders(fields, page, perPage, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.getVimeoFolders']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Array<VimeoUploadLinkRequestRequest>} vimeoUploadLinkRequestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVimeoUploadLinkBatch(vimeoUploadLinkRequestRequest: Array<VimeoUploadLinkRequestRequest>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VimeoUploadLinkResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVimeoUploadLinkBatch(vimeoUploadLinkRequestRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.getVimeoUploadLinkBatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Send account activation email
         * @param {number} id A unique integer value identifying this user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendAccountActivationEmail(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendAccountActivationEmail(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.sendAccountActivationEmail']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Send confirmation email
         * @param {number} id A unique integer value identifying this user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendConfirmationEmail(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendConfirmationEmail(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.sendConfirmationEmail']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sharedApiCoursePreviewRetrieve(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourseFrontoffice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sharedApiCoursePreviewRetrieve(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.sharedApiCoursePreviewRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Quiz attempt.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sharedApiQuizAttemptDestroy(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sharedApiQuizAttemptDestroy(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.sharedApiQuizAttemptDestroy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Quiz attempt.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sharedApiQuizAttemptRetrieve(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuizAttempt>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sharedApiQuizAttemptRetrieve(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.sharedApiQuizAttemptRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Quiz attempt.
         * @param {QuizAttemptRequest} quizAttemptRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sharedApiQuizAttemptUpdate(id: number, quizAttemptRequest: QuizAttemptRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuizAttempt>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sharedApiQuizAttemptUpdate(id, quizAttemptRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.sharedApiQuizAttemptUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sharedApiQuizList(limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedQuizList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sharedApiQuizList(limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.sharedApiQuizList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {QuizProblemAttemptedAnswerRequest} quizProblemAttemptedAnswerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sharedApiQuizProblemAttemptedAnswerCreate(quizProblemAttemptedAnswerRequest: QuizProblemAttemptedAnswerRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuizProblemAttemptedAnswer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sharedApiQuizProblemAttemptedAnswerCreate(quizProblemAttemptedAnswerRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.sharedApiQuizProblemAttemptedAnswerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this quiz problem attempted answer.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sharedApiQuizProblemAttemptedAnswerDestroy(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sharedApiQuizProblemAttemptedAnswerDestroy(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.sharedApiQuizProblemAttemptedAnswerDestroy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sharedApiQuizProblemAttemptedAnswerList(limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedQuizProblemAttemptedAnswerList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sharedApiQuizProblemAttemptedAnswerList(limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.sharedApiQuizProblemAttemptedAnswerList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this quiz problem attempted answer.
         * @param {PatchedQuizProblemAttemptedAnswerRequest} [patchedQuizProblemAttemptedAnswerRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sharedApiQuizProblemAttemptedAnswerPartialUpdate(id: number, patchedQuizProblemAttemptedAnswerRequest?: PatchedQuizProblemAttemptedAnswerRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuizProblemAttemptedAnswer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sharedApiQuizProblemAttemptedAnswerPartialUpdate(id, patchedQuizProblemAttemptedAnswerRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.sharedApiQuizProblemAttemptedAnswerPartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this quiz problem attempted answer.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sharedApiQuizProblemAttemptedAnswerRetrieve(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuizProblemAttemptedAnswer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sharedApiQuizProblemAttemptedAnswerRetrieve(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.sharedApiQuizProblemAttemptedAnswerRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this quiz problem attempted answer.
         * @param {QuizProblemAttemptedAnswerRequest} quizProblemAttemptedAnswerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sharedApiQuizProblemAttemptedAnswerUpdate(id: number, quizProblemAttemptedAnswerRequest: QuizProblemAttemptedAnswerRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuizProblemAttemptedAnswer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sharedApiQuizProblemAttemptedAnswerUpdate(id, quizProblemAttemptedAnswerRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.sharedApiQuizProblemAttemptedAnswerUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this quiz.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sharedApiQuizSnapshotRetrieve(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuizQuizSnapshot>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sharedApiQuizSnapshotRetrieve(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.sharedApiQuizSnapshotRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UserDataFormAttemptRequest} userDataFormAttemptRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sharedApiUserDataFormAttemptCreate(userDataFormAttemptRequest: UserDataFormAttemptRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDataFormAttempt>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sharedApiUserDataFormAttemptCreate(userDataFormAttemptRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.sharedApiUserDataFormAttemptCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this User onboarding attempt.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sharedApiUserDataFormAttemptDestroy(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sharedApiUserDataFormAttemptDestroy(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.sharedApiUserDataFormAttemptDestroy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this User onboarding attempt.
         * @param {PatchedUserDataFormAttemptRequest} [patchedUserDataFormAttemptRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sharedApiUserDataFormAttemptPartialUpdate(id: number, patchedUserDataFormAttemptRequest?: PatchedUserDataFormAttemptRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDataFormAttempt>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sharedApiUserDataFormAttemptPartialUpdate(id, patchedUserDataFormAttemptRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.sharedApiUserDataFormAttemptPartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this User onboarding attempt.
         * @param {UserDataFormAttemptRequest} userDataFormAttemptRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sharedApiUserDataFormAttemptUpdate(id: number, userDataFormAttemptRequest: UserDataFormAttemptRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDataFormAttempt>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sharedApiUserDataFormAttemptUpdate(id, userDataFormAttemptRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.sharedApiUserDataFormAttemptUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sharedApiUserDataFormPreviewRetrieve(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDataForm>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sharedApiUserDataFormPreviewRetrieve(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.sharedApiUserDataFormPreviewRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sharedApiUserDataFormScreenList(limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedUserDataFormStepList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sharedApiUserDataFormScreenList(limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.sharedApiUserDataFormScreenList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sharedApiUserList(limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedUserList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sharedApiUserList(limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.sharedApiUserList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sharedApiUserRetrieve(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sharedApiUserRetrieve(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.sharedApiUserRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sharedApiVimeoCreateFolderCreate(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sharedApiVimeoCreateFolderCreate(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.sharedApiVimeoCreateFolderCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UserDataFormAttemptRequest} userDataFormAttemptRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOrCreateUserDataFormAttempt(userDataFormAttemptRequest: UserDataFormAttemptRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDataFormAttempt>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrCreateUserDataFormAttempt(userDataFormAttemptRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.updateOrCreateUserDataFormAttempt']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this Quiz attempt.
         * @param {PatchedQuizAttemptUpdateRequestRequest} [patchedQuizAttemptUpdateRequestRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateQuizAttempt(id: number, patchedQuizAttemptUpdateRequestRequest?: PatchedQuizAttemptUpdateRequestRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuizAttempt>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateQuizAttempt(id, patchedQuizAttemptUpdateRequestRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharedApi.updateQuizAttempt']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SharedApi - factory interface
 * @export
 */
export const SharedApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SharedApiFp(configuration)
    return {
        /**
         * 
         * @param {SharedApiCreateOrUpdateAttemptedAnswerRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateAttemptedAnswer(requestParameters: SharedApiCreateOrUpdateAttemptedAnswerRequest, options?: RawAxiosRequestConfig): AxiosPromise<QuizProblemAttemptedAnswer> {
            return localVarFp.createOrUpdateAttemptedAnswer(requestParameters.quizProblemAttemptedAnswerRequestRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiCreateQuizAttemptRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createQuizAttempt(requestParameters: SharedApiCreateQuizAttemptRequest, options?: RawAxiosRequestConfig): AxiosPromise<QuizAttemptCreateResponse> {
            return localVarFp.createQuizAttempt(requestParameters.quizAttemptCreateRequestRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiFinishQuizAttemptRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finishQuizAttempt(requestParameters: SharedApiFinishQuizAttemptRequest, options?: RawAxiosRequestConfig): AxiosPromise<DiscardQuizAttemptResponse> {
            return localVarFp.finishQuizAttempt(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiFinishQuizAttempt2Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finishQuizAttempt2(requestParameters: SharedApiFinishQuizAttempt2Request, options?: RawAxiosRequestConfig): AxiosPromise<FinishQuizAttemptResponse> {
            return localVarFp.finishQuizAttempt2(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Generates a zip file with the matura exam problem sources.
         * @param {SharedApiGenerateMaturaDownloaderZipRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateMaturaDownloaderZip(requestParameters: SharedApiGenerateMaturaDownloaderZipRequest, options?: RawAxiosRequestConfig): AxiosPromise<MaturaDownloaderGenerateZipResponse> {
            return localVarFp.generateMaturaDownloaderZip(requestParameters.maturaDownloaderGenerateZipRequestRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiGetActiveQuizAttemptRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveQuizAttempt(requestParameters: SharedApiGetActiveQuizAttemptRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<QuizAttempt> {
            return localVarFp.getActiveQuizAttempt(requestParameters.quiz, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiGetCourseRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourse(requestParameters: SharedApiGetCourseRequest, options?: RawAxiosRequestConfig): AxiosPromise<CourseFrontoffice> {
            return localVarFp.getCourse(requestParameters.id, requestParameters.contentType, requestParameters.inPreviewPanel, requestParameters.token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiGetCourseCategoryRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseCategory(requestParameters: SharedApiGetCourseCategoryRequest, options?: RawAxiosRequestConfig): AxiosPromise<CourseCategory> {
            return localVarFp.getCourseCategory(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiGetCourseCourseModulesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseCourseModules(requestParameters: SharedApiGetCourseCourseModulesRequest, options?: RawAxiosRequestConfig): AxiosPromise<CourseWithCourseModulesFrontoffice> {
            return localVarFp.getCourseCourseModules(requestParameters.id, requestParameters.contentType, requestParameters.inPreviewPanel, requestParameters.token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiGetCourseDigitalTextbookRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseDigitalTextbook(requestParameters: SharedApiGetCourseDigitalTextbookRequest, options?: RawAxiosRequestConfig): AxiosPromise<CourseWithDigitalTextbookFrontoffice> {
            return localVarFp.getCourseDigitalTextbook(requestParameters.id, requestParameters.contentType, requestParameters.inPreviewPanel, requestParameters.token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiGetCourseProblemSourceListingsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseProblemSourceListings(requestParameters: SharedApiGetCourseProblemSourceListingsRequest, options?: RawAxiosRequestConfig): AxiosPromise<CourseWithProblemSourceListingsFrontoffice> {
            return localVarFp.getCourseProblemSourceListings(requestParameters.id, requestParameters.contentType, requestParameters.inPreviewPanel, requestParameters.token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiGetCourseVideoListingsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCourseVideoListings(requestParameters: SharedApiGetCourseVideoListingsRequest, options?: RawAxiosRequestConfig): AxiosPromise<CourseWithVideoListingFrontoffice> {
            return localVarFp.getCourseVideoListings(requestParameters.id, requestParameters.contentType, requestParameters.inPreviewPanel, requestParameters.token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiGetDigitalTextbookRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDigitalTextbook(requestParameters: SharedApiGetDigitalTextbookRequest, options?: RawAxiosRequestConfig): AxiosPromise<DigitalTextbook> {
            return localVarFp.getDigitalTextbook(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiGetDigitalTextbookListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDigitalTextbookList(requestParameters: SharedApiGetDigitalTextbookListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedDigitalTextbookListList> {
            return localVarFp.getDigitalTextbookList(requestParameters.digitalTextbookSubject, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiGetQuizRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuiz(requestParameters: SharedApiGetQuizRequest, options?: RawAxiosRequestConfig): AxiosPromise<Quiz> {
            return localVarFp.getQuiz(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiGetQuizAttemptListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuizAttemptList(requestParameters: SharedApiGetQuizAttemptListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedQuizAttemptListList> {
            return localVarFp.getQuizAttemptList(requestParameters.finishedAt, requestParameters.limit, requestParameters.offset, requestParameters.quiz, requestParameters.user, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiGetQuizAttemptListByUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuizAttemptListByUser(requestParameters: SharedApiGetQuizAttemptListByUserRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedQuizAttemptListList> {
            return localVarFp.getQuizAttemptListByUser(requestParameters.quizAttemptRequest, requestParameters.finishedAt, requestParameters.limit, requestParameters.offset, requestParameters.quiz, requestParameters.user, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiGetQuizAttemptResultsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuizAttemptResults(requestParameters: SharedApiGetQuizAttemptResultsRequest, options?: RawAxiosRequestConfig): AxiosPromise<QuizAttemptResults> {
            return localVarFp.getQuizAttemptResults(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiGetUserDataFormRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserDataForm(requestParameters: SharedApiGetUserDataFormRequest, options?: RawAxiosRequestConfig): AxiosPromise<UserDataForm> {
            return localVarFp.getUserDataForm(requestParameters.id, requestParameters.contentType, requestParameters.inPreviewPanel, requestParameters.token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiGetUserDataFormAttemptRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserDataFormAttempt(requestParameters: SharedApiGetUserDataFormAttemptRequest, options?: RawAxiosRequestConfig): AxiosPromise<UserDataFormAttempt> {
            return localVarFp.getUserDataFormAttempt(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiGetUserDataFormAttemptListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserDataFormAttemptList(requestParameters: SharedApiGetUserDataFormAttemptListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedUserDataFormAttemptListList> {
            return localVarFp.getUserDataFormAttemptList(requestParameters.limit, requestParameters.offset, requestParameters.userDataForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiGetUserDataFormListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserDataFormList(requestParameters: SharedApiGetUserDataFormListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedUserDataFormListList> {
            return localVarFp.getUserDataFormList(requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiGetUserDataFormResponsesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserDataFormResponses(requestParameters: SharedApiGetUserDataFormResponsesRequest, options?: RawAxiosRequestConfig): AxiosPromise<UserDataFormResponses> {
            return localVarFp.getUserDataFormResponses(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiGetUserDataFormStepRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserDataFormStep(requestParameters: SharedApiGetUserDataFormStepRequest, options?: RawAxiosRequestConfig): AxiosPromise<UserDataFormStep> {
            return localVarFp.getUserDataFormStep(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiGetVimeoFoldersRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVimeoFolders(requestParameters: SharedApiGetVimeoFoldersRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<VimeoFoldersResponse> {
            return localVarFp.getVimeoFolders(requestParameters.fields, requestParameters.page, requestParameters.perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiGetVimeoUploadLinkBatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVimeoUploadLinkBatch(requestParameters: SharedApiGetVimeoUploadLinkBatchRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<VimeoUploadLinkResponse>> {
            return localVarFp.getVimeoUploadLinkBatch(requestParameters.vimeoUploadLinkRequestRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send account activation email
         * @param {SharedApiSendAccountActivationEmailRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendAccountActivationEmail(requestParameters: SharedApiSendAccountActivationEmailRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.sendAccountActivationEmail(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send confirmation email
         * @param {SharedApiSendConfirmationEmailRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendConfirmationEmail(requestParameters: SharedApiSendConfirmationEmailRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.sendConfirmationEmail(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sharedApiCoursePreviewRetrieve(options?: RawAxiosRequestConfig): AxiosPromise<CourseFrontoffice> {
            return localVarFp.sharedApiCoursePreviewRetrieve(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiSharedApiQuizAttemptDestroyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sharedApiQuizAttemptDestroy(requestParameters: SharedApiSharedApiQuizAttemptDestroyRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.sharedApiQuizAttemptDestroy(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiSharedApiQuizAttemptRetrieveRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sharedApiQuizAttemptRetrieve(requestParameters: SharedApiSharedApiQuizAttemptRetrieveRequest, options?: RawAxiosRequestConfig): AxiosPromise<QuizAttempt> {
            return localVarFp.sharedApiQuizAttemptRetrieve(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiSharedApiQuizAttemptUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sharedApiQuizAttemptUpdate(requestParameters: SharedApiSharedApiQuizAttemptUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<QuizAttempt> {
            return localVarFp.sharedApiQuizAttemptUpdate(requestParameters.id, requestParameters.quizAttemptRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiSharedApiQuizListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sharedApiQuizList(requestParameters: SharedApiSharedApiQuizListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedQuizList> {
            return localVarFp.sharedApiQuizList(requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiSharedApiQuizProblemAttemptedAnswerCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sharedApiQuizProblemAttemptedAnswerCreate(requestParameters: SharedApiSharedApiQuizProblemAttemptedAnswerCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<QuizProblemAttemptedAnswer> {
            return localVarFp.sharedApiQuizProblemAttemptedAnswerCreate(requestParameters.quizProblemAttemptedAnswerRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiSharedApiQuizProblemAttemptedAnswerDestroyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sharedApiQuizProblemAttemptedAnswerDestroy(requestParameters: SharedApiSharedApiQuizProblemAttemptedAnswerDestroyRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.sharedApiQuizProblemAttemptedAnswerDestroy(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiSharedApiQuizProblemAttemptedAnswerListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sharedApiQuizProblemAttemptedAnswerList(requestParameters: SharedApiSharedApiQuizProblemAttemptedAnswerListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedQuizProblemAttemptedAnswerList> {
            return localVarFp.sharedApiQuizProblemAttemptedAnswerList(requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiSharedApiQuizProblemAttemptedAnswerPartialUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sharedApiQuizProblemAttemptedAnswerPartialUpdate(requestParameters: SharedApiSharedApiQuizProblemAttemptedAnswerPartialUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<QuizProblemAttemptedAnswer> {
            return localVarFp.sharedApiQuizProblemAttemptedAnswerPartialUpdate(requestParameters.id, requestParameters.patchedQuizProblemAttemptedAnswerRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiSharedApiQuizProblemAttemptedAnswerRetrieveRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sharedApiQuizProblemAttemptedAnswerRetrieve(requestParameters: SharedApiSharedApiQuizProblemAttemptedAnswerRetrieveRequest, options?: RawAxiosRequestConfig): AxiosPromise<QuizProblemAttemptedAnswer> {
            return localVarFp.sharedApiQuizProblemAttemptedAnswerRetrieve(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiSharedApiQuizProblemAttemptedAnswerUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sharedApiQuizProblemAttemptedAnswerUpdate(requestParameters: SharedApiSharedApiQuizProblemAttemptedAnswerUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<QuizProblemAttemptedAnswer> {
            return localVarFp.sharedApiQuizProblemAttemptedAnswerUpdate(requestParameters.id, requestParameters.quizProblemAttemptedAnswerRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiSharedApiQuizSnapshotRetrieveRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sharedApiQuizSnapshotRetrieve(requestParameters: SharedApiSharedApiQuizSnapshotRetrieveRequest, options?: RawAxiosRequestConfig): AxiosPromise<QuizQuizSnapshot> {
            return localVarFp.sharedApiQuizSnapshotRetrieve(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiSharedApiUserDataFormAttemptCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sharedApiUserDataFormAttemptCreate(requestParameters: SharedApiSharedApiUserDataFormAttemptCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<UserDataFormAttempt> {
            return localVarFp.sharedApiUserDataFormAttemptCreate(requestParameters.userDataFormAttemptRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiSharedApiUserDataFormAttemptDestroyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sharedApiUserDataFormAttemptDestroy(requestParameters: SharedApiSharedApiUserDataFormAttemptDestroyRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.sharedApiUserDataFormAttemptDestroy(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiSharedApiUserDataFormAttemptPartialUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sharedApiUserDataFormAttemptPartialUpdate(requestParameters: SharedApiSharedApiUserDataFormAttemptPartialUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<UserDataFormAttempt> {
            return localVarFp.sharedApiUserDataFormAttemptPartialUpdate(requestParameters.id, requestParameters.patchedUserDataFormAttemptRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiSharedApiUserDataFormAttemptUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sharedApiUserDataFormAttemptUpdate(requestParameters: SharedApiSharedApiUserDataFormAttemptUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<UserDataFormAttempt> {
            return localVarFp.sharedApiUserDataFormAttemptUpdate(requestParameters.id, requestParameters.userDataFormAttemptRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sharedApiUserDataFormPreviewRetrieve(options?: RawAxiosRequestConfig): AxiosPromise<UserDataForm> {
            return localVarFp.sharedApiUserDataFormPreviewRetrieve(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiSharedApiUserDataFormScreenListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sharedApiUserDataFormScreenList(requestParameters: SharedApiSharedApiUserDataFormScreenListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedUserDataFormStepList> {
            return localVarFp.sharedApiUserDataFormScreenList(requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiSharedApiUserListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sharedApiUserList(requestParameters: SharedApiSharedApiUserListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedUserList> {
            return localVarFp.sharedApiUserList(requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiSharedApiUserRetrieveRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sharedApiUserRetrieve(requestParameters: SharedApiSharedApiUserRetrieveRequest, options?: RawAxiosRequestConfig): AxiosPromise<User> {
            return localVarFp.sharedApiUserRetrieve(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sharedApiVimeoCreateFolderCreate(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.sharedApiVimeoCreateFolderCreate(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiUpdateOrCreateUserDataFormAttemptRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrCreateUserDataFormAttempt(requestParameters: SharedApiUpdateOrCreateUserDataFormAttemptRequest, options?: RawAxiosRequestConfig): AxiosPromise<UserDataFormAttempt> {
            return localVarFp.updateOrCreateUserDataFormAttempt(requestParameters.userDataFormAttemptRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SharedApiUpdateQuizAttemptRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateQuizAttempt(requestParameters: SharedApiUpdateQuizAttemptRequest, options?: RawAxiosRequestConfig): AxiosPromise<QuizAttempt> {
            return localVarFp.updateQuizAttempt(requestParameters.id, requestParameters.patchedQuizAttemptUpdateRequestRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createOrUpdateAttemptedAnswer operation in SharedApi.
 * @export
 * @interface SharedApiCreateOrUpdateAttemptedAnswerRequest
 */
export interface SharedApiCreateOrUpdateAttemptedAnswerRequest {
    /**
     * 
     * @type {QuizProblemAttemptedAnswerRequestRequest}
     * @memberof SharedApiCreateOrUpdateAttemptedAnswer
     */
    readonly quizProblemAttemptedAnswerRequestRequest: QuizProblemAttemptedAnswerRequestRequest
}

/**
 * Request parameters for createQuizAttempt operation in SharedApi.
 * @export
 * @interface SharedApiCreateQuizAttemptRequest
 */
export interface SharedApiCreateQuizAttemptRequest {
    /**
     * 
     * @type {QuizAttemptCreateRequestRequest}
     * @memberof SharedApiCreateQuizAttempt
     */
    readonly quizAttemptCreateRequestRequest: QuizAttemptCreateRequestRequest
}

/**
 * Request parameters for finishQuizAttempt operation in SharedApi.
 * @export
 * @interface SharedApiFinishQuizAttemptRequest
 */
export interface SharedApiFinishQuizAttemptRequest {
    /**
     * A unique integer value identifying this Quiz attempt.
     * @type {number}
     * @memberof SharedApiFinishQuizAttempt
     */
    readonly id: number
}

/**
 * Request parameters for finishQuizAttempt2 operation in SharedApi.
 * @export
 * @interface SharedApiFinishQuizAttempt2Request
 */
export interface SharedApiFinishQuizAttempt2Request {
    /**
     * A unique integer value identifying this Quiz attempt.
     * @type {number}
     * @memberof SharedApiFinishQuizAttempt2
     */
    readonly id: number
}

/**
 * Request parameters for generateMaturaDownloaderZip operation in SharedApi.
 * @export
 * @interface SharedApiGenerateMaturaDownloaderZipRequest
 */
export interface SharedApiGenerateMaturaDownloaderZipRequest {
    /**
     * 
     * @type {MaturaDownloaderGenerateZipRequestRequest}
     * @memberof SharedApiGenerateMaturaDownloaderZip
     */
    readonly maturaDownloaderGenerateZipRequestRequest: MaturaDownloaderGenerateZipRequestRequest
}

/**
 * Request parameters for getActiveQuizAttempt operation in SharedApi.
 * @export
 * @interface SharedApiGetActiveQuizAttemptRequest
 */
export interface SharedApiGetActiveQuizAttemptRequest {
    /**
     * 
     * @type {number}
     * @memberof SharedApiGetActiveQuizAttempt
     */
    readonly quiz?: number
}

/**
 * Request parameters for getCourse operation in SharedApi.
 * @export
 * @interface SharedApiGetCourseRequest
 */
export interface SharedApiGetCourseRequest {
    /**
     * A unique integer value identifying this course.
     * @type {number}
     * @memberof SharedApiGetCourse
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof SharedApiGetCourse
     */
    readonly contentType?: string

    /**
     * 
     * @type {boolean}
     * @memberof SharedApiGetCourse
     */
    readonly inPreviewPanel?: boolean

    /**
     * 
     * @type {string}
     * @memberof SharedApiGetCourse
     */
    readonly token?: string
}

/**
 * Request parameters for getCourseCategory operation in SharedApi.
 * @export
 * @interface SharedApiGetCourseCategoryRequest
 */
export interface SharedApiGetCourseCategoryRequest {
    /**
     * A unique integer value identifying this course.
     * @type {number}
     * @memberof SharedApiGetCourseCategory
     */
    readonly id: number
}

/**
 * Request parameters for getCourseCourseModules operation in SharedApi.
 * @export
 * @interface SharedApiGetCourseCourseModulesRequest
 */
export interface SharedApiGetCourseCourseModulesRequest {
    /**
     * A unique integer value identifying this course.
     * @type {number}
     * @memberof SharedApiGetCourseCourseModules
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof SharedApiGetCourseCourseModules
     */
    readonly contentType?: string

    /**
     * 
     * @type {boolean}
     * @memberof SharedApiGetCourseCourseModules
     */
    readonly inPreviewPanel?: boolean

    /**
     * 
     * @type {string}
     * @memberof SharedApiGetCourseCourseModules
     */
    readonly token?: string
}

/**
 * Request parameters for getCourseDigitalTextbook operation in SharedApi.
 * @export
 * @interface SharedApiGetCourseDigitalTextbookRequest
 */
export interface SharedApiGetCourseDigitalTextbookRequest {
    /**
     * A unique integer value identifying this course.
     * @type {number}
     * @memberof SharedApiGetCourseDigitalTextbook
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof SharedApiGetCourseDigitalTextbook
     */
    readonly contentType?: string

    /**
     * 
     * @type {boolean}
     * @memberof SharedApiGetCourseDigitalTextbook
     */
    readonly inPreviewPanel?: boolean

    /**
     * 
     * @type {string}
     * @memberof SharedApiGetCourseDigitalTextbook
     */
    readonly token?: string
}

/**
 * Request parameters for getCourseProblemSourceListings operation in SharedApi.
 * @export
 * @interface SharedApiGetCourseProblemSourceListingsRequest
 */
export interface SharedApiGetCourseProblemSourceListingsRequest {
    /**
     * A unique integer value identifying this course.
     * @type {number}
     * @memberof SharedApiGetCourseProblemSourceListings
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof SharedApiGetCourseProblemSourceListings
     */
    readonly contentType?: string

    /**
     * 
     * @type {boolean}
     * @memberof SharedApiGetCourseProblemSourceListings
     */
    readonly inPreviewPanel?: boolean

    /**
     * 
     * @type {string}
     * @memberof SharedApiGetCourseProblemSourceListings
     */
    readonly token?: string
}

/**
 * Request parameters for getCourseVideoListings operation in SharedApi.
 * @export
 * @interface SharedApiGetCourseVideoListingsRequest
 */
export interface SharedApiGetCourseVideoListingsRequest {
    /**
     * A unique integer value identifying this course.
     * @type {number}
     * @memberof SharedApiGetCourseVideoListings
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof SharedApiGetCourseVideoListings
     */
    readonly contentType?: string

    /**
     * 
     * @type {boolean}
     * @memberof SharedApiGetCourseVideoListings
     */
    readonly inPreviewPanel?: boolean

    /**
     * 
     * @type {string}
     * @memberof SharedApiGetCourseVideoListings
     */
    readonly token?: string
}

/**
 * Request parameters for getDigitalTextbook operation in SharedApi.
 * @export
 * @interface SharedApiGetDigitalTextbookRequest
 */
export interface SharedApiGetDigitalTextbookRequest {
    /**
     * A unique integer value identifying this digital textbook.
     * @type {number}
     * @memberof SharedApiGetDigitalTextbook
     */
    readonly id: number
}

/**
 * Request parameters for getDigitalTextbookList operation in SharedApi.
 * @export
 * @interface SharedApiGetDigitalTextbookListRequest
 */
export interface SharedApiGetDigitalTextbookListRequest {
    /**
     * 
     * @type {number}
     * @memberof SharedApiGetDigitalTextbookList
     */
    readonly digitalTextbookSubject?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof SharedApiGetDigitalTextbookList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof SharedApiGetDigitalTextbookList
     */
    readonly offset?: number
}

/**
 * Request parameters for getQuiz operation in SharedApi.
 * @export
 * @interface SharedApiGetQuizRequest
 */
export interface SharedApiGetQuizRequest {
    /**
     * A unique integer value identifying this quiz.
     * @type {number}
     * @memberof SharedApiGetQuiz
     */
    readonly id: number
}

/**
 * Request parameters for getQuizAttemptList operation in SharedApi.
 * @export
 * @interface SharedApiGetQuizAttemptListRequest
 */
export interface SharedApiGetQuizAttemptListRequest {
    /**
     * 
     * @type {string}
     * @memberof SharedApiGetQuizAttemptList
     */
    readonly finishedAt?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof SharedApiGetQuizAttemptList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof SharedApiGetQuizAttemptList
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof SharedApiGetQuizAttemptList
     */
    readonly quiz?: number

    /**
     * 
     * @type {number}
     * @memberof SharedApiGetQuizAttemptList
     */
    readonly user?: number
}

/**
 * Request parameters for getQuizAttemptListByUser operation in SharedApi.
 * @export
 * @interface SharedApiGetQuizAttemptListByUserRequest
 */
export interface SharedApiGetQuizAttemptListByUserRequest {
    /**
     * 
     * @type {QuizAttemptRequest}
     * @memberof SharedApiGetQuizAttemptListByUser
     */
    readonly quizAttemptRequest: QuizAttemptRequest

    /**
     * 
     * @type {string}
     * @memberof SharedApiGetQuizAttemptListByUser
     */
    readonly finishedAt?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof SharedApiGetQuizAttemptListByUser
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof SharedApiGetQuizAttemptListByUser
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof SharedApiGetQuizAttemptListByUser
     */
    readonly quiz?: number

    /**
     * 
     * @type {number}
     * @memberof SharedApiGetQuizAttemptListByUser
     */
    readonly user?: number
}

/**
 * Request parameters for getQuizAttemptResults operation in SharedApi.
 * @export
 * @interface SharedApiGetQuizAttemptResultsRequest
 */
export interface SharedApiGetQuizAttemptResultsRequest {
    /**
     * A unique integer value identifying this Quiz attempt.
     * @type {number}
     * @memberof SharedApiGetQuizAttemptResults
     */
    readonly id: number
}

/**
 * Request parameters for getUserDataForm operation in SharedApi.
 * @export
 * @interface SharedApiGetUserDataFormRequest
 */
export interface SharedApiGetUserDataFormRequest {
    /**
     * A unique integer value identifying this user data form.
     * @type {number}
     * @memberof SharedApiGetUserDataForm
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof SharedApiGetUserDataForm
     */
    readonly contentType?: string

    /**
     * 
     * @type {boolean}
     * @memberof SharedApiGetUserDataForm
     */
    readonly inPreviewPanel?: boolean

    /**
     * 
     * @type {string}
     * @memberof SharedApiGetUserDataForm
     */
    readonly token?: string
}

/**
 * Request parameters for getUserDataFormAttempt operation in SharedApi.
 * @export
 * @interface SharedApiGetUserDataFormAttemptRequest
 */
export interface SharedApiGetUserDataFormAttemptRequest {
    /**
     * A unique integer value identifying this User onboarding attempt.
     * @type {number}
     * @memberof SharedApiGetUserDataFormAttempt
     */
    readonly id: number
}

/**
 * Request parameters for getUserDataFormAttemptList operation in SharedApi.
 * @export
 * @interface SharedApiGetUserDataFormAttemptListRequest
 */
export interface SharedApiGetUserDataFormAttemptListRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof SharedApiGetUserDataFormAttemptList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof SharedApiGetUserDataFormAttemptList
     */
    readonly offset?: number

    /**
     * 
     * @type {number}
     * @memberof SharedApiGetUserDataFormAttemptList
     */
    readonly userDataForm?: number
}

/**
 * Request parameters for getUserDataFormList operation in SharedApi.
 * @export
 * @interface SharedApiGetUserDataFormListRequest
 */
export interface SharedApiGetUserDataFormListRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof SharedApiGetUserDataFormList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof SharedApiGetUserDataFormList
     */
    readonly offset?: number
}

/**
 * Request parameters for getUserDataFormResponses operation in SharedApi.
 * @export
 * @interface SharedApiGetUserDataFormResponsesRequest
 */
export interface SharedApiGetUserDataFormResponsesRequest {
    /**
     * A unique integer value identifying this user data form.
     * @type {number}
     * @memberof SharedApiGetUserDataFormResponses
     */
    readonly id: number
}

/**
 * Request parameters for getUserDataFormStep operation in SharedApi.
 * @export
 * @interface SharedApiGetUserDataFormStepRequest
 */
export interface SharedApiGetUserDataFormStepRequest {
    /**
     * A unique integer value identifying this user data form step.
     * @type {number}
     * @memberof SharedApiGetUserDataFormStep
     */
    readonly id: number
}

/**
 * Request parameters for getVimeoFolders operation in SharedApi.
 * @export
 * @interface SharedApiGetVimeoFoldersRequest
 */
export interface SharedApiGetVimeoFoldersRequest {
    /**
     * 
     * @type {string}
     * @memberof SharedApiGetVimeoFolders
     */
    readonly fields?: string

    /**
     * 
     * @type {number}
     * @memberof SharedApiGetVimeoFolders
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof SharedApiGetVimeoFolders
     */
    readonly perPage?: number
}

/**
 * Request parameters for getVimeoUploadLinkBatch operation in SharedApi.
 * @export
 * @interface SharedApiGetVimeoUploadLinkBatchRequest
 */
export interface SharedApiGetVimeoUploadLinkBatchRequest {
    /**
     * 
     * @type {Array<VimeoUploadLinkRequestRequest>}
     * @memberof SharedApiGetVimeoUploadLinkBatch
     */
    readonly vimeoUploadLinkRequestRequest: Array<VimeoUploadLinkRequestRequest>
}

/**
 * Request parameters for sendAccountActivationEmail operation in SharedApi.
 * @export
 * @interface SharedApiSendAccountActivationEmailRequest
 */
export interface SharedApiSendAccountActivationEmailRequest {
    /**
     * A unique integer value identifying this user.
     * @type {number}
     * @memberof SharedApiSendAccountActivationEmail
     */
    readonly id: number
}

/**
 * Request parameters for sendConfirmationEmail operation in SharedApi.
 * @export
 * @interface SharedApiSendConfirmationEmailRequest
 */
export interface SharedApiSendConfirmationEmailRequest {
    /**
     * A unique integer value identifying this user.
     * @type {number}
     * @memberof SharedApiSendConfirmationEmail
     */
    readonly id: number
}

/**
 * Request parameters for sharedApiQuizAttemptDestroy operation in SharedApi.
 * @export
 * @interface SharedApiSharedApiQuizAttemptDestroyRequest
 */
export interface SharedApiSharedApiQuizAttemptDestroyRequest {
    /**
     * A unique integer value identifying this Quiz attempt.
     * @type {number}
     * @memberof SharedApiSharedApiQuizAttemptDestroy
     */
    readonly id: number
}

/**
 * Request parameters for sharedApiQuizAttemptRetrieve operation in SharedApi.
 * @export
 * @interface SharedApiSharedApiQuizAttemptRetrieveRequest
 */
export interface SharedApiSharedApiQuizAttemptRetrieveRequest {
    /**
     * A unique integer value identifying this Quiz attempt.
     * @type {number}
     * @memberof SharedApiSharedApiQuizAttemptRetrieve
     */
    readonly id: number
}

/**
 * Request parameters for sharedApiQuizAttemptUpdate operation in SharedApi.
 * @export
 * @interface SharedApiSharedApiQuizAttemptUpdateRequest
 */
export interface SharedApiSharedApiQuizAttemptUpdateRequest {
    /**
     * A unique integer value identifying this Quiz attempt.
     * @type {number}
     * @memberof SharedApiSharedApiQuizAttemptUpdate
     */
    readonly id: number

    /**
     * 
     * @type {QuizAttemptRequest}
     * @memberof SharedApiSharedApiQuizAttemptUpdate
     */
    readonly quizAttemptRequest: QuizAttemptRequest
}

/**
 * Request parameters for sharedApiQuizList operation in SharedApi.
 * @export
 * @interface SharedApiSharedApiQuizListRequest
 */
export interface SharedApiSharedApiQuizListRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof SharedApiSharedApiQuizList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof SharedApiSharedApiQuizList
     */
    readonly offset?: number
}

/**
 * Request parameters for sharedApiQuizProblemAttemptedAnswerCreate operation in SharedApi.
 * @export
 * @interface SharedApiSharedApiQuizProblemAttemptedAnswerCreateRequest
 */
export interface SharedApiSharedApiQuizProblemAttemptedAnswerCreateRequest {
    /**
     * 
     * @type {QuizProblemAttemptedAnswerRequest}
     * @memberof SharedApiSharedApiQuizProblemAttemptedAnswerCreate
     */
    readonly quizProblemAttemptedAnswerRequest: QuizProblemAttemptedAnswerRequest
}

/**
 * Request parameters for sharedApiQuizProblemAttemptedAnswerDestroy operation in SharedApi.
 * @export
 * @interface SharedApiSharedApiQuizProblemAttemptedAnswerDestroyRequest
 */
export interface SharedApiSharedApiQuizProblemAttemptedAnswerDestroyRequest {
    /**
     * A unique integer value identifying this quiz problem attempted answer.
     * @type {number}
     * @memberof SharedApiSharedApiQuizProblemAttemptedAnswerDestroy
     */
    readonly id: number
}

/**
 * Request parameters for sharedApiQuizProblemAttemptedAnswerList operation in SharedApi.
 * @export
 * @interface SharedApiSharedApiQuizProblemAttemptedAnswerListRequest
 */
export interface SharedApiSharedApiQuizProblemAttemptedAnswerListRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof SharedApiSharedApiQuizProblemAttemptedAnswerList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof SharedApiSharedApiQuizProblemAttemptedAnswerList
     */
    readonly offset?: number
}

/**
 * Request parameters for sharedApiQuizProblemAttemptedAnswerPartialUpdate operation in SharedApi.
 * @export
 * @interface SharedApiSharedApiQuizProblemAttemptedAnswerPartialUpdateRequest
 */
export interface SharedApiSharedApiQuizProblemAttemptedAnswerPartialUpdateRequest {
    /**
     * A unique integer value identifying this quiz problem attempted answer.
     * @type {number}
     * @memberof SharedApiSharedApiQuizProblemAttemptedAnswerPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {PatchedQuizProblemAttemptedAnswerRequest}
     * @memberof SharedApiSharedApiQuizProblemAttemptedAnswerPartialUpdate
     */
    readonly patchedQuizProblemAttemptedAnswerRequest?: PatchedQuizProblemAttemptedAnswerRequest
}

/**
 * Request parameters for sharedApiQuizProblemAttemptedAnswerRetrieve operation in SharedApi.
 * @export
 * @interface SharedApiSharedApiQuizProblemAttemptedAnswerRetrieveRequest
 */
export interface SharedApiSharedApiQuizProblemAttemptedAnswerRetrieveRequest {
    /**
     * A unique integer value identifying this quiz problem attempted answer.
     * @type {number}
     * @memberof SharedApiSharedApiQuizProblemAttemptedAnswerRetrieve
     */
    readonly id: number
}

/**
 * Request parameters for sharedApiQuizProblemAttemptedAnswerUpdate operation in SharedApi.
 * @export
 * @interface SharedApiSharedApiQuizProblemAttemptedAnswerUpdateRequest
 */
export interface SharedApiSharedApiQuizProblemAttemptedAnswerUpdateRequest {
    /**
     * A unique integer value identifying this quiz problem attempted answer.
     * @type {number}
     * @memberof SharedApiSharedApiQuizProblemAttemptedAnswerUpdate
     */
    readonly id: number

    /**
     * 
     * @type {QuizProblemAttemptedAnswerRequest}
     * @memberof SharedApiSharedApiQuizProblemAttemptedAnswerUpdate
     */
    readonly quizProblemAttemptedAnswerRequest: QuizProblemAttemptedAnswerRequest
}

/**
 * Request parameters for sharedApiQuizSnapshotRetrieve operation in SharedApi.
 * @export
 * @interface SharedApiSharedApiQuizSnapshotRetrieveRequest
 */
export interface SharedApiSharedApiQuizSnapshotRetrieveRequest {
    /**
     * A unique integer value identifying this quiz.
     * @type {number}
     * @memberof SharedApiSharedApiQuizSnapshotRetrieve
     */
    readonly id: number
}

/**
 * Request parameters for sharedApiUserDataFormAttemptCreate operation in SharedApi.
 * @export
 * @interface SharedApiSharedApiUserDataFormAttemptCreateRequest
 */
export interface SharedApiSharedApiUserDataFormAttemptCreateRequest {
    /**
     * 
     * @type {UserDataFormAttemptRequest}
     * @memberof SharedApiSharedApiUserDataFormAttemptCreate
     */
    readonly userDataFormAttemptRequest: UserDataFormAttemptRequest
}

/**
 * Request parameters for sharedApiUserDataFormAttemptDestroy operation in SharedApi.
 * @export
 * @interface SharedApiSharedApiUserDataFormAttemptDestroyRequest
 */
export interface SharedApiSharedApiUserDataFormAttemptDestroyRequest {
    /**
     * A unique integer value identifying this User onboarding attempt.
     * @type {number}
     * @memberof SharedApiSharedApiUserDataFormAttemptDestroy
     */
    readonly id: number
}

/**
 * Request parameters for sharedApiUserDataFormAttemptPartialUpdate operation in SharedApi.
 * @export
 * @interface SharedApiSharedApiUserDataFormAttemptPartialUpdateRequest
 */
export interface SharedApiSharedApiUserDataFormAttemptPartialUpdateRequest {
    /**
     * A unique integer value identifying this User onboarding attempt.
     * @type {number}
     * @memberof SharedApiSharedApiUserDataFormAttemptPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {PatchedUserDataFormAttemptRequest}
     * @memberof SharedApiSharedApiUserDataFormAttemptPartialUpdate
     */
    readonly patchedUserDataFormAttemptRequest?: PatchedUserDataFormAttemptRequest
}

/**
 * Request parameters for sharedApiUserDataFormAttemptUpdate operation in SharedApi.
 * @export
 * @interface SharedApiSharedApiUserDataFormAttemptUpdateRequest
 */
export interface SharedApiSharedApiUserDataFormAttemptUpdateRequest {
    /**
     * A unique integer value identifying this User onboarding attempt.
     * @type {number}
     * @memberof SharedApiSharedApiUserDataFormAttemptUpdate
     */
    readonly id: number

    /**
     * 
     * @type {UserDataFormAttemptRequest}
     * @memberof SharedApiSharedApiUserDataFormAttemptUpdate
     */
    readonly userDataFormAttemptRequest: UserDataFormAttemptRequest
}

/**
 * Request parameters for sharedApiUserDataFormScreenList operation in SharedApi.
 * @export
 * @interface SharedApiSharedApiUserDataFormScreenListRequest
 */
export interface SharedApiSharedApiUserDataFormScreenListRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof SharedApiSharedApiUserDataFormScreenList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof SharedApiSharedApiUserDataFormScreenList
     */
    readonly offset?: number
}

/**
 * Request parameters for sharedApiUserList operation in SharedApi.
 * @export
 * @interface SharedApiSharedApiUserListRequest
 */
export interface SharedApiSharedApiUserListRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof SharedApiSharedApiUserList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof SharedApiSharedApiUserList
     */
    readonly offset?: number
}

/**
 * Request parameters for sharedApiUserRetrieve operation in SharedApi.
 * @export
 * @interface SharedApiSharedApiUserRetrieveRequest
 */
export interface SharedApiSharedApiUserRetrieveRequest {
    /**
     * A unique integer value identifying this user.
     * @type {number}
     * @memberof SharedApiSharedApiUserRetrieve
     */
    readonly id: number
}

/**
 * Request parameters for updateOrCreateUserDataFormAttempt operation in SharedApi.
 * @export
 * @interface SharedApiUpdateOrCreateUserDataFormAttemptRequest
 */
export interface SharedApiUpdateOrCreateUserDataFormAttemptRequest {
    /**
     * 
     * @type {UserDataFormAttemptRequest}
     * @memberof SharedApiUpdateOrCreateUserDataFormAttempt
     */
    readonly userDataFormAttemptRequest: UserDataFormAttemptRequest
}

/**
 * Request parameters for updateQuizAttempt operation in SharedApi.
 * @export
 * @interface SharedApiUpdateQuizAttemptRequest
 */
export interface SharedApiUpdateQuizAttemptRequest {
    /**
     * A unique integer value identifying this Quiz attempt.
     * @type {number}
     * @memberof SharedApiUpdateQuizAttempt
     */
    readonly id: number

    /**
     * 
     * @type {PatchedQuizAttemptUpdateRequestRequest}
     * @memberof SharedApiUpdateQuizAttempt
     */
    readonly patchedQuizAttemptUpdateRequestRequest?: PatchedQuizAttemptUpdateRequestRequest
}

/**
 * SharedApi - object-oriented interface
 * @export
 * @class SharedApi
 * @extends {BaseAPI}
 */
export class SharedApi extends BaseAPI {
    /**
     * 
     * @param {SharedApiCreateOrUpdateAttemptedAnswerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public createOrUpdateAttemptedAnswer(requestParameters: SharedApiCreateOrUpdateAttemptedAnswerRequest, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).createOrUpdateAttemptedAnswer(requestParameters.quizProblemAttemptedAnswerRequestRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiCreateQuizAttemptRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public createQuizAttempt(requestParameters: SharedApiCreateQuizAttemptRequest, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).createQuizAttempt(requestParameters.quizAttemptCreateRequestRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiFinishQuizAttemptRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public finishQuizAttempt(requestParameters: SharedApiFinishQuizAttemptRequest, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).finishQuizAttempt(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiFinishQuizAttempt2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public finishQuizAttempt2(requestParameters: SharedApiFinishQuizAttempt2Request, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).finishQuizAttempt2(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Generates a zip file with the matura exam problem sources.
     * @param {SharedApiGenerateMaturaDownloaderZipRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public generateMaturaDownloaderZip(requestParameters: SharedApiGenerateMaturaDownloaderZipRequest, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).generateMaturaDownloaderZip(requestParameters.maturaDownloaderGenerateZipRequestRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiGetActiveQuizAttemptRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public getActiveQuizAttempt(requestParameters: SharedApiGetActiveQuizAttemptRequest = {}, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).getActiveQuizAttempt(requestParameters.quiz, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiGetCourseRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public getCourse(requestParameters: SharedApiGetCourseRequest, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).getCourse(requestParameters.id, requestParameters.contentType, requestParameters.inPreviewPanel, requestParameters.token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiGetCourseCategoryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public getCourseCategory(requestParameters: SharedApiGetCourseCategoryRequest, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).getCourseCategory(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiGetCourseCourseModulesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public getCourseCourseModules(requestParameters: SharedApiGetCourseCourseModulesRequest, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).getCourseCourseModules(requestParameters.id, requestParameters.contentType, requestParameters.inPreviewPanel, requestParameters.token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiGetCourseDigitalTextbookRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public getCourseDigitalTextbook(requestParameters: SharedApiGetCourseDigitalTextbookRequest, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).getCourseDigitalTextbook(requestParameters.id, requestParameters.contentType, requestParameters.inPreviewPanel, requestParameters.token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiGetCourseProblemSourceListingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public getCourseProblemSourceListings(requestParameters: SharedApiGetCourseProblemSourceListingsRequest, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).getCourseProblemSourceListings(requestParameters.id, requestParameters.contentType, requestParameters.inPreviewPanel, requestParameters.token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiGetCourseVideoListingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public getCourseVideoListings(requestParameters: SharedApiGetCourseVideoListingsRequest, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).getCourseVideoListings(requestParameters.id, requestParameters.contentType, requestParameters.inPreviewPanel, requestParameters.token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiGetDigitalTextbookRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public getDigitalTextbook(requestParameters: SharedApiGetDigitalTextbookRequest, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).getDigitalTextbook(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiGetDigitalTextbookListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public getDigitalTextbookList(requestParameters: SharedApiGetDigitalTextbookListRequest = {}, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).getDigitalTextbookList(requestParameters.digitalTextbookSubject, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiGetQuizRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public getQuiz(requestParameters: SharedApiGetQuizRequest, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).getQuiz(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiGetQuizAttemptListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public getQuizAttemptList(requestParameters: SharedApiGetQuizAttemptListRequest = {}, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).getQuizAttemptList(requestParameters.finishedAt, requestParameters.limit, requestParameters.offset, requestParameters.quiz, requestParameters.user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiGetQuizAttemptListByUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public getQuizAttemptListByUser(requestParameters: SharedApiGetQuizAttemptListByUserRequest, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).getQuizAttemptListByUser(requestParameters.quizAttemptRequest, requestParameters.finishedAt, requestParameters.limit, requestParameters.offset, requestParameters.quiz, requestParameters.user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiGetQuizAttemptResultsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public getQuizAttemptResults(requestParameters: SharedApiGetQuizAttemptResultsRequest, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).getQuizAttemptResults(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiGetUserDataFormRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public getUserDataForm(requestParameters: SharedApiGetUserDataFormRequest, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).getUserDataForm(requestParameters.id, requestParameters.contentType, requestParameters.inPreviewPanel, requestParameters.token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiGetUserDataFormAttemptRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public getUserDataFormAttempt(requestParameters: SharedApiGetUserDataFormAttemptRequest, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).getUserDataFormAttempt(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiGetUserDataFormAttemptListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public getUserDataFormAttemptList(requestParameters: SharedApiGetUserDataFormAttemptListRequest = {}, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).getUserDataFormAttemptList(requestParameters.limit, requestParameters.offset, requestParameters.userDataForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiGetUserDataFormListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public getUserDataFormList(requestParameters: SharedApiGetUserDataFormListRequest = {}, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).getUserDataFormList(requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiGetUserDataFormResponsesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public getUserDataFormResponses(requestParameters: SharedApiGetUserDataFormResponsesRequest, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).getUserDataFormResponses(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiGetUserDataFormStepRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public getUserDataFormStep(requestParameters: SharedApiGetUserDataFormStepRequest, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).getUserDataFormStep(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiGetVimeoFoldersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public getVimeoFolders(requestParameters: SharedApiGetVimeoFoldersRequest = {}, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).getVimeoFolders(requestParameters.fields, requestParameters.page, requestParameters.perPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiGetVimeoUploadLinkBatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public getVimeoUploadLinkBatch(requestParameters: SharedApiGetVimeoUploadLinkBatchRequest, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).getVimeoUploadLinkBatch(requestParameters.vimeoUploadLinkRequestRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send account activation email
     * @param {SharedApiSendAccountActivationEmailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public sendAccountActivationEmail(requestParameters: SharedApiSendAccountActivationEmailRequest, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).sendAccountActivationEmail(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send confirmation email
     * @param {SharedApiSendConfirmationEmailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public sendConfirmationEmail(requestParameters: SharedApiSendConfirmationEmailRequest, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).sendConfirmationEmail(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public sharedApiCoursePreviewRetrieve(options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).sharedApiCoursePreviewRetrieve(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiSharedApiQuizAttemptDestroyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public sharedApiQuizAttemptDestroy(requestParameters: SharedApiSharedApiQuizAttemptDestroyRequest, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).sharedApiQuizAttemptDestroy(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiSharedApiQuizAttemptRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public sharedApiQuizAttemptRetrieve(requestParameters: SharedApiSharedApiQuizAttemptRetrieveRequest, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).sharedApiQuizAttemptRetrieve(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiSharedApiQuizAttemptUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public sharedApiQuizAttemptUpdate(requestParameters: SharedApiSharedApiQuizAttemptUpdateRequest, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).sharedApiQuizAttemptUpdate(requestParameters.id, requestParameters.quizAttemptRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiSharedApiQuizListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public sharedApiQuizList(requestParameters: SharedApiSharedApiQuizListRequest = {}, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).sharedApiQuizList(requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiSharedApiQuizProblemAttemptedAnswerCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public sharedApiQuizProblemAttemptedAnswerCreate(requestParameters: SharedApiSharedApiQuizProblemAttemptedAnswerCreateRequest, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).sharedApiQuizProblemAttemptedAnswerCreate(requestParameters.quizProblemAttemptedAnswerRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiSharedApiQuizProblemAttemptedAnswerDestroyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public sharedApiQuizProblemAttemptedAnswerDestroy(requestParameters: SharedApiSharedApiQuizProblemAttemptedAnswerDestroyRequest, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).sharedApiQuizProblemAttemptedAnswerDestroy(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiSharedApiQuizProblemAttemptedAnswerListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public sharedApiQuizProblemAttemptedAnswerList(requestParameters: SharedApiSharedApiQuizProblemAttemptedAnswerListRequest = {}, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).sharedApiQuizProblemAttemptedAnswerList(requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiSharedApiQuizProblemAttemptedAnswerPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public sharedApiQuizProblemAttemptedAnswerPartialUpdate(requestParameters: SharedApiSharedApiQuizProblemAttemptedAnswerPartialUpdateRequest, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).sharedApiQuizProblemAttemptedAnswerPartialUpdate(requestParameters.id, requestParameters.patchedQuizProblemAttemptedAnswerRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiSharedApiQuizProblemAttemptedAnswerRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public sharedApiQuizProblemAttemptedAnswerRetrieve(requestParameters: SharedApiSharedApiQuizProblemAttemptedAnswerRetrieveRequest, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).sharedApiQuizProblemAttemptedAnswerRetrieve(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiSharedApiQuizProblemAttemptedAnswerUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public sharedApiQuizProblemAttemptedAnswerUpdate(requestParameters: SharedApiSharedApiQuizProblemAttemptedAnswerUpdateRequest, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).sharedApiQuizProblemAttemptedAnswerUpdate(requestParameters.id, requestParameters.quizProblemAttemptedAnswerRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiSharedApiQuizSnapshotRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public sharedApiQuizSnapshotRetrieve(requestParameters: SharedApiSharedApiQuizSnapshotRetrieveRequest, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).sharedApiQuizSnapshotRetrieve(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiSharedApiUserDataFormAttemptCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public sharedApiUserDataFormAttemptCreate(requestParameters: SharedApiSharedApiUserDataFormAttemptCreateRequest, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).sharedApiUserDataFormAttemptCreate(requestParameters.userDataFormAttemptRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiSharedApiUserDataFormAttemptDestroyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public sharedApiUserDataFormAttemptDestroy(requestParameters: SharedApiSharedApiUserDataFormAttemptDestroyRequest, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).sharedApiUserDataFormAttemptDestroy(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiSharedApiUserDataFormAttemptPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public sharedApiUserDataFormAttemptPartialUpdate(requestParameters: SharedApiSharedApiUserDataFormAttemptPartialUpdateRequest, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).sharedApiUserDataFormAttemptPartialUpdate(requestParameters.id, requestParameters.patchedUserDataFormAttemptRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiSharedApiUserDataFormAttemptUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public sharedApiUserDataFormAttemptUpdate(requestParameters: SharedApiSharedApiUserDataFormAttemptUpdateRequest, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).sharedApiUserDataFormAttemptUpdate(requestParameters.id, requestParameters.userDataFormAttemptRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public sharedApiUserDataFormPreviewRetrieve(options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).sharedApiUserDataFormPreviewRetrieve(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiSharedApiUserDataFormScreenListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public sharedApiUserDataFormScreenList(requestParameters: SharedApiSharedApiUserDataFormScreenListRequest = {}, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).sharedApiUserDataFormScreenList(requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiSharedApiUserListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public sharedApiUserList(requestParameters: SharedApiSharedApiUserListRequest = {}, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).sharedApiUserList(requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiSharedApiUserRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public sharedApiUserRetrieve(requestParameters: SharedApiSharedApiUserRetrieveRequest, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).sharedApiUserRetrieve(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public sharedApiVimeoCreateFolderCreate(options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).sharedApiVimeoCreateFolderCreate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiUpdateOrCreateUserDataFormAttemptRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public updateOrCreateUserDataFormAttempt(requestParameters: SharedApiUpdateOrCreateUserDataFormAttemptRequest, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).updateOrCreateUserDataFormAttempt(requestParameters.userDataFormAttemptRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SharedApiUpdateQuizAttemptRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public updateQuizAttempt(requestParameters: SharedApiUpdateQuizAttemptRequest, options?: RawAxiosRequestConfig) {
        return SharedApiFp(this.configuration).updateQuizAttempt(requestParameters.id, requestParameters.patchedQuizAttemptUpdateRequestRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

