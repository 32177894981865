/* tslint:disable */
/* eslint-disable */
/**
 * Gradivo API
 * API endpoints for Gradivo.hr
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * * `attempt_in_progress` - Attempt in progress * `locked_for_user` - Locked for user * `coming_soon` - Coming soon * `on_cooldown` - On cooldown * `available` - Available * `does_not_exist` - Does not exist
 * @export
 * @enum {string}
 */

export const MaturaExamQuizStatusEnum = {
    AttemptInProgress: 'attempt_in_progress',
    LockedForUser: 'locked_for_user',
    ComingSoon: 'coming_soon',
    OnCooldown: 'on_cooldown',
    Available: 'available',
    DoesNotExist: 'does_not_exist'
} as const;

export type MaturaExamQuizStatusEnum = typeof MaturaExamQuizStatusEnum[keyof typeof MaturaExamQuizStatusEnum];



